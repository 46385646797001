<template>
    <div ref="quillEditor" class="editor-container"></div>
</template>
  
<script>
import Quill from 'quill';
  
export default {
    name: 'QuillEditor',
    data() {
        return {
            // Remove editor from data() since we don't need reactivity
        };
    },
    props: {
        initialContent: {
            type: String,
            default: ''
        }
    },
    mounted() {
        // Use a direct reference instead of this.editor
        let editor = new Quill(this.$refs.quillEditor, {
            theme: 'snow', // Specify theme ('snow' or 'bubble')
            modules: {
                toolbar: [
                    ['bold', 'italic', 'underline', 'strike'], 
                    [{ 'header': 1 }, { 'header': 2 }],
                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                    [{ 'indent': '-1'}, { 'indent': '+1' }],
                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                    [{ 'align': [] }],
                ]
            }
        });

        if (this.initialContent) {
            try {
                // If initialContent is a Delta JSON string, parse it
                const deltaContent = JSON.parse(this.initialContent);
                editor.setContents(deltaContent);
            } catch (e) {
                // If parsing fails, treat it as plain text
                editor.setText(this.initialContent);
            }
        }

        editor.on('text-change', (delta, oldDelta, source) => {
            const html = editor.root.innerHTML;
            const fullDelta = editor.getContents(); // Get the full delta of the editor
            this.$emit('update:content', html);
            this.$emit('update:delta', fullDelta); // Emitting the full delta
        });

        // Store editor reference on the instance for cleanup
        this._editor = editor;
    },
    beforeDestroy() {
        if (this._editor) {
            this._editor.off('text-change');
        }
    }
}
</script>
  
<style>
.editor-container {
    height: 400px; /* Set the height of the editor */
}
</style>