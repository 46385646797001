<template>
    <div class="min-h-screen flex items-center justify-center py-5">
        <div class="w-full max-w-7xl overflow-hidden mx-4 pb-4">
            <div class="mb-6">
                <h2 v-if="!loading" class="text-title-md2 font-semibold text-black dark:text-white">Jobs <span class="text-sm text-gray-600">{{ companyName ? '[Posted by ' + companyName + ']': '' }}</span></h2>
            </div>

            <TableJobs  :jobs='jobs' />
        </div>
    </div>
</template>

<script>
import TableJobs from '@/components/marketplace/admin/TableJobs'

import adminService from '@/services/marketplace/admin.service'

export default {
    name: 'AdminCompanyList',
    inject: ['toggleLoading'],
    components: {
        TableJobs,
    },
    data() {
        return {
            loading: true,
            jobs: [],
            companyName: ''
        }
    },
    methods: {
        fetchJobs() {
            this.toggleLoading(true)
            adminService.getJobs(this.$route.params.companyId)
            .then(response => {
                this.jobs = response.data
                if (this.jobs.length > 0 && this.$route.params.companyId) {
                    this.companyName = this.jobs[0]['companyName']
                }
            })
            .catch((error) => {
                alert(error)
                // this.$router.push('/dashboard/auth');
            })
            .finally(() => {
                this.loading = false
                this.toggleLoading(false);
            })
        }
    },
    created() {
        this.fetchJobs()
    }
}
</script>