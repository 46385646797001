<template>
  <div class="bg-white rounded-xl shadow-sm border border-gray-100">
    <div class="px-8 py-6 border-b border-gray-100">
      <h2 class="text-xl font-semibold text-gray-800">Payment History</h2>
      <p class="mt-1 text-sm text-gray-500">View all your successful transactions</p>
    </div>
    
    <div class="overflow-x-auto">
      <table class="w-full">
        <thead>
          <tr class="bg-gray-50 border-b border-gray-100">
            <th class="px-8 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Transaction Date</th>
            <th class="px-8 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Description</th>
            <th class="px-8 py-4 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
            <th class="px-8 py-4 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Receipt</th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-100">
          <tr v-for="transaction in transactions" 
              :key="transaction.id"
              class="hover:bg-gray-50 transition-colors">
            <td class="px-8 py-4">
              <div class="text-sm text-gray-900">{{ transaction.date }}</div>
            </td>
            <td class="px-8 py-4">
              <div class="text-sm text-gray-900">{{ transaction.jobTitle }}</div>
              <div class="text-xs text-gray-500 mt-1">Job Posting</div>
            </td>
            <td class="px-8 py-4 text-right">
              <div class="text-sm font-medium text-gray-900">${{ transaction.amount }}</div>
            </td>
            <td class="px-8 py-4 text-right">
              <a
                v-if="transaction.receiptUrl"
                :href="transaction.receiptUrl"
                class="inline-flex items-center text-sm text-indigo-600 hover:text-indigo-900"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span class="mr-1">Download</span>
                <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"/>
                </svg>
              </a>
            </td>
          </tr>
        </tbody>
      </table>

      <div v-if="!transactions.length" class="text-center py-16">
        <svg class="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
        </svg>
        <h3 class="mt-4 text-sm font-medium text-gray-900">No payment history</h3>
        <p class="mt-1 text-sm text-gray-500">You haven't made any payments yet.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaymentHistory',
  data() {
    return {
      transactions: [
        {
          id: '1',
          date: 'Apr 15, 2024',
          jobTitle: 'Senior Frontend Developer',
          amount: '299.00',
          receiptUrl: '#'
        },
        {
          id: '2',
          date: 'Apr 12, 2024',
          jobTitle: 'Full Stack Engineer',
          amount: '299.00',
          receiptUrl: '#'
        },
        {
          id: '3',
          date: 'Apr 10, 2024',
          jobTitle: 'Product Designer',
          amount: '299.00',
          receiptUrl: '#'
        },
        {
          id: '4',
          date: 'Apr 5, 2024',
          jobTitle: 'DevOps Engineer',
          amount: '299.00',
          receiptUrl: '#'
        },
        {
          id: '5',
          date: 'Apr 1, 2024',
          jobTitle: 'React Native Developer',
          amount: '299.00',
          receiptUrl: '#'
        }
      ]
    }
  }
}
</script>

<style scoped>
.table-container {
  @apply divide-y divide-gray-200;
}

tbody tr {
  @apply transition-colors;
}

td {
  @apply whitespace-nowrap;
}
</style> 