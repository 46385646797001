<template>
    <div class="min-h-screen flex items-center justify-center py-5">
        <div class="w-full max-w-7xl overflow-hidden mx-4 pb-4">
            <div class="mb-6">
                <h2 class="text-title-md2 font-semibold text-black dark:text-white">Contractors</h2>
            </div>

            <TableContractors  :contractors='contractors' />
        </div>
    </div>
</template>

<script>
import TableContractors from '@/components/marketplace/admin/TableContractors.vue'

import adminService from '@/services/marketplace/admin.service'

export default {
    name: 'AdminContractorList',
    inject: ['toggleLoading'],
    components: {
        TableContractors,
    },
    data() {
        return {
            contractors: []
        }
    },
    methods: {
        fetchContractors() {
            this.toggleLoading(true)
            adminService.getContractors()
            .then(response => {
                this.contractors = response.data
            })
            .catch((error) => {
                this.$router.push('/dashboard/auth');
            })
            .finally(() => {
              this.toggleLoading(false);
            })
        },
    },
    created() {
        this.fetchContractors()
    }
}
</script>