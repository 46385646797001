<template>
  <div class="pt-7 flex justify-center">
    <div class="bg-white shadow-md rounded-xl px-8 pt-6 pb-8 mb-4 flex flex-col w-11/12 md:w-1/2">
      <div class="-mt-6 -ml-8 -mr-8 rounded-t-xl bg-sky-500 pt-4 pl-6 md:pl-8">
        <div class="leading-none tracking-tight mb-3 md:mb-4 text-2xl md:text-3xl text-gray-50">Contractor Login</div>
      </div>

      <div v-if="this.error" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-4" role="alert">
        <span class="block sm:inline">{{ errorMessage }}</span>
      </div>

      <!-- Form Fields -->
      <div class="flex flex-wrap my-6">
        <div class="w-full">
          <label class="block tracking-wide text-gray-800 text-sm mb-2" for="email">
            Email
          </label>
          <input @keydown.enter="submitLogin" class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="email" type="email" v-model="email">
        </div>
      </div>

      <div class="flex flex-wrap mb-6">
        <div class="w-full">
          <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-password">
            Password
          </label>
          <input @keydown.enter="submitLogin" class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="password" type="password" v-model="password">
        </div>
      </div>

      <div class="flex items-end justify-between">
        <router-link to="/contractor/forgot-password">
          <button class="group relative inline-flex items-center overflow-hidden rounded-full bg-sky-500 px-6 py-2 text-white hover:bg-sky-400 focus:outline-none focus:ring active:bg-sky-600" type="button">
            <span class="text-sm font-medium">
              Forgot password
            </span>
          </button>
        </router-link>

        <button class="group relative inline-flex items-center overflow-hidden rounded-full bg-sky-500 px-6 py-2 text-white hover:bg-sky-400 focus:outline-none focus:ring active:bg-sky-600" type="button" @click="submitLogin">
          <span class="text-sm font-medium">
            {{ SignInButtonText }}
          </span>
        </button>
      </div>

      <!-- "Not Registered?" Section -->
      <div class="mt-8 text-center">
        <div class="border-t pt-6 pb-4">
          <p class="text-gray-700 text-sm">Not registered yet?</p>
          <router-link to="/contractor/register" class="text-sky-500 hover:text-sky-600 font-medium inline-block mt-1 transition-all duration-200 hover:scale-105">
            Create a free account
          </router-link>
        </div>

        <div class="relative">
          <div class="absolute inset-0 flex items-center">
            <div class="w-full border-t border-gray-200"></div>
          </div>
          <div class="relative flex justify-center">
            <span class="bg-white px-4 text-sm text-gray-500">or</span>
          </div>
        </div>

        <div class="mt-6 bg-gradient-to-r from-sky-50 to-indigo-50 rounded-lg p-6 shadow-sm hover:shadow-md transition-all duration-300">
          <div class="flex flex-col items-center">
            <div class="text-lg font-semibold text-gray-800 mb-2">Are you hiring?</div>
            <p class="text-gray-600 text-sm mb-3">Access our talented pool of contractors</p>
            <router-link to="/company/login" class="group relative inline-flex items-center overflow-hidden rounded-full bg-sky-500 px-8 py-3 text-white focus:outline-none focus:ring active:bg-sky-600">
              <span class="absolute -end-full transition-all group-hover:end-4">
                →
              </span>
              <span class="text-sm font-medium transition-all group-hover:me-4">
                Switch to Company Login
              </span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let apiEndpoint = process.env.VUE_APP_API_BASEURL

export default {
  name: 'Login',
  inject: ['toggleLoading'],
  components: {
  },
  data() {
    return {
      email:"",
      password: "",
      error: false,
      errorMessage: '',
      SignInButtonText: "Sign In"
    };
  },
  methods: {
    submitLogin() {
      this.toggleLoading(true);

      this.error = false
      if (this.email != "" && this.password != "") {
        // Build submission form
        let formData = new FormData();
        formData.append('contractorEmail', this.email);
        formData.append('contractorPass', this.password);

        fetch(apiEndpoint + "/auth.php", {
            body: formData,
            method: "POST"
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                this.toggleLoading(false);
                // Set state
                this.$store.commit('setContractorLoggedIn', true)

                // Write token to localStorage
                localStorage.setItem('contractorToken', data.token);

                // Write contractor name to localStorage
                localStorage.setItem('contractorName', data.name)
                this.$store.commit('setContractorName', data.name)

                // Remove company token
                localStorage.removeItem('companyToken');

                this.toggleLoading(false)
                // Redirect to profiles

                this.$router.push(this.$route.query.redirect_uri || '/contractor/profile');
            } else {
                // Clear values
                this.email = ""
                this.password = ""

                // Set state
                this.$store.commit('setContractorLoggedIn', false)
                // Remove token from local storage
                localStorage.removeItem('contractorToken');
                // Remove contractor name
                localStorage.removeItem('contractorName');

                // Set error to true
                this.error = true
                this.errorMessage = 'You have entered an invalid username or password.'
                this.toggleLoading(false)
            }
        })
        .catch(error => {
            // Handle errors here
            this.error = true
            this.toggleLoading(false);
            this.errorMessage = 'Unable to process your login request. Please try again later.'
        });
      } else {
        this.error = true
        this.toggleLoading(false);
        this.errorMessage = 'Please fill in the username and password fields.'
      }
    },
  },
  created() {
      const token = localStorage.getItem('contractorToken');
      if (token) {
        // Optionally, validate the token if necessary
        this.$store.commit('setContractorLoggedIn', true)

        if (this.$route.query.redirect_uri) {
          this.$router.push(this.$route.query.redirect_uri);
        } else {
          this.$router.push('/contractor/profile');
        }
      }
  }
}
</script>
