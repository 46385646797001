<script setup>
import { ref, onMounted } from 'vue'
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net-dt';

const props = defineProps({
  jobs: Array,
});

const options = {
    lengthChange: false,
    order: [[7, 'desc']]
}

const columns = [
    { data: 'title', title: 'Title', width: '20%' },
    { data: 'industryName', title: 'Industry' },
    { data: 'roleName', title: 'Role'},
    { data: 'companyName', title: 'Company Name', width: '20%'},
    { data: 'work_location', title: 'Location' },
    {
        title: 'Active',
        render: function ( data, type, row) {
            if (row['is_active'] == 1) {
                return 'Yes'
            } else {
                return 'No'
            }
        },
    },
    { data: 'totalApplications', title: 'Applicants', type: 'num', width: '5%' },
    {
        data: 'creation_date',
        title: 'Posted',
        render: function ( data, type, row) {
            return row['creation_date'].split(' ')[0]
        },
        type: 'date',
        width: '10%'
    },
    {
        data: null,
        render: function ( data, type, row) {
            let c = '<a class="px-3" href="/dashboard/job/' + row['uuid'] + '">'
            c += '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 hover:cursor-pointer hover:text-sky-500"><path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" /><path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" /></svg>'
            c += '</a>'
            return c
        },
        searchable: false,
        orderable: false,
        targets: -1,
    }
]
DataTable.use(DataTablesCore);

let dt;
const table = ref(); // This variable is used in the `ref` attribute for the component
 
onMounted(function () {
    dt = table.value.dt;
});

</script>

<style>
/* @import 'datatables.net-dt'; */
</style>

<template>
        <DataTable ref="table" class="cell-border" :columns="columns" :data="jobs" :options="options"></DataTable>
</template>