import axios from 'axios';

let apiEndpoint = process.env.VUE_APP_API_BASEURL

export default {
    async generateAuthenticationRequest(email) {
        return await axios.post(`${apiEndpoint}/dashboard/auth`, { email: email }, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
    },
    async verifyAuthenticationToken(token) {
        return axios.get(`${apiEndpoint}/dashboard/auth?token=` + token, {
            withCredentials: true
        })
    },
    getLatestActivity() {
        return axios.get(`${apiEndpoint}/dashboard/activity`, {
            withCredentials: true
        })
    },
    getStats() {
        return axios.get(`${apiEndpoint}/dashboard/stats`, {
            withCredentials: true
        })
    },
    getCompanies() {
        return axios.get(`${apiEndpoint}/dashboard/companies`, {
            withCredentials: true
        })
    },
    getJob(jobUUID) {
        return axios.get(`${apiEndpoint}/dashboard/job/` + jobUUID, {
            withCredentials: true
        })
    },
    getJobs(companyId) {
        const url = companyId ? `${apiEndpoint}/dashboard/jobs/${companyId}` : `${apiEndpoint}/dashboard/jobs`;
        return axios.get(url, {
            withCredentials: true
        });
    },
    getContractors() {
        return axios.get(`${apiEndpoint}/dashboard/contractors`, {
            withCredentials: true
        })
    },
    getContractor(contractorId) {
        return axios.get(`${apiEndpoint}/dashboard/contractors/` + contractorId, {
            withCredentials: true
        })
    },
    async getContractorResume(contractorId) {
        return axios.get(`${apiEndpoint}/dashboard/contractors/` + contractorId + '/resume', {
            withCredentials: true,
            responseType: 'blob'
        })
    },
    async getHiddenProfile(contractorId) {
        return axios.get(`${apiEndpoint}/dashboard/contractors/` + contractorId + '/hidden-profile', {
            withCredentials: true,
            responseType: 'blob'
        })
    },
    getChart(size) {
        return axios.get(`${apiEndpoint}/dashboard/chart/` + size, {
            withCredentials: true
        })
    }
}