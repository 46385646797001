<template>
    <div class="mx-auto p-6">
      <!-- Success Message -->
      <transition name="fade">
        <div v-if="showSuccessMessage" class="mb-6 bg-green-50 border border-green-200 rounded-lg p-4">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <svg class="h-5 w-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
              </svg>
            </div>
            <div class="ml-3">
              <h3 class="text-sm font-medium text-green-800">Payment Successful</h3>
              <div class="mt-2 text-sm text-green-700">
                <p>Your job posting has been published and is now live. You can manage it from this page.</p>
              </div>
            </div>
          </div>
        </div>
      </transition>

      <!-- Add these modals before the main content -->
      <!-- Delete Confirmation Modal -->
      <div v-if="showDeleteModal" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
        <div class="relative top-20 mx-auto p-5 border w-[440px] shadow-lg rounded-md bg-white">
          <div class="mt-3">
            <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
              <svg class="h-6 w-6 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/>
              </svg>
            </div>
            <h3 class="text-lg leading-6 font-medium text-gray-900 mt-4 text-center">Delete Draft Job</h3>

            <div class="mt-4 px-4">
              <div class="bg-amber-50 border border-amber-100 rounded-lg p-4 mb-4">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <svg class="h-5 w-5 text-amber-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                    </svg>
                  </div>
                  <div class="ml-3">
                    <p class="text-sm text-amber-800">
                      You're about to delete a draft job posting. This action cannot be undone.
                    </p>
                  </div>
                </div>
              </div>

              <div class="space-y-2 text-sm text-gray-600">
                <p>What you should know:</p>
                <ul class="list-disc pl-5 space-y-1">
                  <li>All draft content will be permanently deleted</li>
                  <li>You'll need to start over if you want to post this job later</li>
                </ul>
              </div>

              <div class="mt-6">
                <label class="flex items-center space-x-2">
                  <input 
                    type="checkbox" 
                    v-model="deleteDraftConfirmation"
                    class="h-4 w-4 text-red-600 rounded border-gray-300 focus:ring-red-500"
                  >
                  <span class="text-sm text-gray-700">Yes, delete this draft job posting</span>
                </label>
              </div>
            </div>

            <div class="flex justify-end gap-3 mt-6">
              <button 
                @click="showDeleteModal = false" 
                class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                Cancel
              </button>
              <button
                @click="confirmDelete"
                :disabled="!deleteDraftConfirmation"
                class="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Delete Draft
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Close Job Modal -->
      <div v-if="showCloseModal" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
        <div class="relative top-20 mx-auto p-5 border w-[480px] shadow-lg rounded-md bg-white">
          <div class="mt-3">
            <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
              <svg class="h-6 w-6 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
              </svg>
            </div>
            <h3 class="text-lg leading-6 font-medium text-gray-900 mt-4 text-center">Close Job Posting</h3>
            
            <div class="mt-4 px-4">
              <div class="bg-sky-50 border border-sky-100 rounded-lg p-4 mb-4">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <svg class="h-5 w-5 text-sky-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                    </svg>
                  </div>
                  <div class="ml-3">
                    <p class="text-sm text-sky-900">
                      This job posting still has <span class="font-medium">{{ getRemainingDays(selectedJob?.published_date) }} days</span> remaining. Closing it now will forfeit the remaining time.
                    </p>
                  </div>
                </div>
              </div>

              <div class="space-y-3 text-sm text-gray-600">
                <p>By closing this job posting:</p>
                <ul class="list-disc pl-5 space-y-2">
                  <li>The posting will be permanently marked as "Closed"</li>
                  <li>It will no longer be visible to contractors in the job board</li>
                  <li>Existing applications will still be accessible</li>
                  <li>This action cannot be undone</li>
                </ul>
              </div>

              <div class="mt-6">
                <label class="flex items-center space-x-2">
                  <input 
                    type="checkbox" 
                    v-model="closeJobConfirmation"
                    class="h-4 w-4 text-sky-600 rounded border-gray-300 focus:ring-sky-500"
                  >
                  <span class="text-sm text-gray-700">I understand and want to close this job posting</span>
                </label>
              </div>
            </div>

            <div class="flex justify-end gap-3 mt-6">
              <button 
                @click="showCloseModal = false" 
                class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
              >
                Cancel
              </button>
              <button
                @click="confirmClose"
                :disabled="!closeJobConfirmation"
                class="px-4 py-2 text-sm font-medium text-white bg-sky-600 rounded-md hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Close Job Posting
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Manage Jobs Header -->
      <div class="flex items-center justify-between mb-8">
        <div>
          <h2 class="text-2xl lg:text-3xl font-semibold text-gray-900">Manage Jobs</h2>
          <p class="text-gray-500 mt-1 text-sm lg:text-base">View and manage all your job postings in one place</p>
        </div>
        <router-link to="/company/post-job">
          <button class="bg-sky-500 text-white px-5 py-2 rounded-lg font-medium hover:bg-sky-600 transition-colors duration-200">
            Post New Job
          </button>
        </router-link>
      </div>

      <div class="bg-white rounded-xl shadow-sm border border-gray-100">
        <div class="overflow-x-visible">
          <table class="min-w-full w-full">
            <thead>
              <tr>
                <th class="bg-sky-50 py-4 px-6 text-left text-sm font-semibold text-gray-700">Job Title</th>
                <th class="bg-sky-50 py-4 px-6 text-left text-sm font-semibold text-gray-700">Work Location</th>
                <th class="bg-sky-50 py-4 px-6 text-left text-sm font-semibold text-gray-700">Industry & Role</th>
                <th class="bg-sky-50 py-4 px-6 text-left text-sm font-semibold text-gray-700">Date</th>
                <th class="bg-sky-50 py-4 px-6 text-left text-sm font-semibold text-gray-700">Applicants</th>
                <th class="bg-sky-50 py-4 px-6 text-left text-sm font-semibold text-gray-700">Status</th>
                <th class="bg-sky-50 py-4 px-6 text-right text-sm font-semibold text-gray-700">Actions</th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-100">
              <template v-if="jobs.length > 0">
                <tr v-for="job in jobs" :key="job.id" class="hover:bg-sky-50/30 transition-colors duration-150">
                  <td class="py-4 px-6">
                    <div class="text-sm font-medium text-gray-900">{{ job.title }}</div>
                    <div class="text-xs text-gray-500 mt-1">{{ beautifyText('work_type', job.work_type) }}</div>
                  </td>
                  <td class="py-4 px-6">
                    <div class="text-sm text-gray-900">{{ job.work_location }}</div>
                    <div class="text-xs text-gray-500 mt-1">{{ beautifyText('work_arrangement', job.work_arrangement) }}</div>
                  </td>
                  <td class="py-4 px-6">
                    <div class="text-sm text-gray-900">{{ job.industryName }}</div>
                    <div class="text-xs text-gray-500 mt-1">{{ job.roleName }}</div>
                  </td>
                  <td class="py-4 px-6">
                    <div class="text-sm text-gray-900">
                      <template v-if="job.status === 1">
                        Draft since {{ formatPostDate(job.creation_date) }}
                      </template>
                      <template v-else-if="job.status === 2">
                        Published {{ formatPostDate(job.published_date) }}
                      </template>
                      <template v-else-if="job.status === 3">
                        Expired on {{ formatPostDate(job.expired_date || getExpiryDate(job.published_date)) }}
                      </template>
                      <template v-else-if="job.status === 4">
                        Closed on {{ formatPostDate(job.closed_date) }}
                      </template>
                    </div>
                    <div v-if="job.status === 2" class="text-xs text-gray-500 mt-1">
                      <span :class="{
                        'text-orange-600': getRemainingDays(job.published_date) <= 7,
                        'text-sky-600': getRemainingDays(job.published_date) > 7
                      }">
                        {{ calculateTimeRemaining(job.published_date) }}
                      </span>
                    </div>
                  </td>
                  <td class="py-4 px-6">
                    <div v-if="job.applicants > 0">
                        <router-link 
                            :to="`/company/view-job/${job.uuid}?tab=applicants`"
                            class="text-sm text-sky-600 hover:text-sky-800 font-medium hover:underline"
                        >
                            {{ job.applicants }}
                        </router-link>
                        <div class="text-xs text-gray-500 mt-1">Total applications</div>
                    </div>
                    <div v-else>
                        <div class="text-sm text-gray-900">{{ job.applicants }}</div>
                        <div class="text-xs text-gray-500 mt-1">Total applications</div>
                    </div>
                  </td>
                  <td class="py-4 px-6">
                    <span v-if="job.status === 1" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                        Draft
                    </span>
                    <span v-else-if="job.status === 2" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                        Active
                    </span>
                    <span v-else-if="job.status === 3" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-orange-100 text-orange-800">
                        Expired
                    </span>
                    <span v-else-if="job.status === 4" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                        Closed
                    </span>
                  </td>
                  <td class="py-4 px-6 text-right">
                    <div class="relative inline-block text-left">
                      <button 
                        @click="toggleDropdown(job)"
                        class="p-1.5 rounded-md hover:bg-gray-100 transition-colors duration-200"
                        :class="{ 'bg-gray-100 ring-2 ring-sky-200': activeDropdown === job.id }"
                      >
                        <svg class="w-5 h-5 text-gray-500" :class="{ 'text-sky-600': activeDropdown === job.id }" viewBox="0 0 24 24" fill="currentColor">
                          <path d="M12 3C10.9 3 10 3.9 10 5C10 6.1 10.9 7 12 7C13.1 7 14 6.1 14 5C14 3.9 13.1 3 12 3ZM12 17C10.9 17 10 17.9 10 19C10 20.1 10.9 21 12 21C13.1 21 14 20.1 14 19C14 17.9 13.1 17 12 17ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"/>
                        </svg>
                      </button>

                      <div 
                        v-if="activeDropdown === job.id"
                        class="absolute right-full mr-2 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-[100] before:content-[''] before:absolute before:right-[-6px] before:top-[18px] before:w-2 before:h-2 before:rotate-45 before:bg-white before:border-t before:border-r before:border-gray-200"
                      >
                        <div class="py-1" role="menu">
                          <!-- Draft Status (1) - Edit and Delete only -->
                          <template v-if="job.status === 1">
                            <router-link 
                              :to="`/company/edit-job/${job.uuid}`" 
                              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            >
                              Edit Draft
                            </router-link>
                            <button
                              @click="deleteJob(job)"
                              class="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-red-50"
                            >
                              Delete Draft
                            </button>
                          </template>

                          <!-- Active Status (2) - View, Edit, and Close -->
                          <template v-if="job.status === 2">
                            <router-link 
                              :to="`/company/view-job/${job.uuid}`" 
                              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            >
                              View Job
                            </router-link>
                            <router-link 
                              :to="`/company/edit-job/${job.uuid}`" 
                              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            >
                              Edit Job
                            </router-link>
                            <button
                              @click="closeJob(job)"
                              class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            >
                              Close Job
                            </button>
                          </template>

                          <!-- Expired/Closed Status (3/4) - View only -->
                          <template v-if="job.status === 3 || job.status === 4">
                            <router-link 
                              :to="`/company/view-job/${job.uuid}`" 
                              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            >
                              View Job
                            </router-link>
                          </template>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </template>

              <tr v-else>
                <td colspan="6" class="py-12 text-center">
                  <div class="flex flex-col items-center justify-center space-y-4">
                    <div class="p-3 rounded-full bg-sky-100">
                      <svg class="w-8 h-8 text-sky-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"></path>
                      </svg>
                    </div>
                    <div>
                      <h3 class="text-lg font-medium text-gray-900">No jobs posted yet</h3>
                      <p class="text-gray-500 mt-1">Start posting now to find the perfect candidates!</p>
                    </div>
                    <router-link to="/company/post-job">
                      <button class="bg-sky-500 text-white px-5 py-2 rounded-lg font-medium hover:bg-sky-600 transition-colors duration-200">
                        Post Your First Job
                      </button>
                    </router-link>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import companyService from '@/services/marketplace/company.service';
  import jobsService from '@/services/marketplace/jobs.service';
  
  export default {
    name: 'ManageJobs',
    inject: ['toggleLoading'],
    data() {
      return {
        jobs: [],
        showSuccessMessage: false,
        time_remaining: 1,
        activeDropdown: null,
        showDeleteModal: false,
        showCloseModal: false,
        selectedJob: null,
        closeJobConfirmation: false,
        deleteDraftConfirmation: false,
      };
    },
    methods: { 
      beautifyText(field, value) {
        if (field === 'work_type') {
          if (!value) return '';
          return value.replace('_', ' ')[0].toUpperCase() + value.replace('_', ' ').slice(1);
        } else if (field === 'work_arrangement') {
          switch(value) {
            case 'wfh': return 'WFH';
            case 'field': return 'Field';
            case 'hybrid': return 'Hybrid';
            case 'office': return 'Office / On Site';
            case 'other': return 'Other';
            default: return value;
          }
        }
        return value;
      },
      async fetchJobs() {
        this.toggleLoading(true);
        try {
          companyService.getJobs()
            .then(response => {
              this.jobs = response.data;
              this.toggleLoading(false);
            })
            .catch(error => {
              this.toggleLoading(false);
              console.error('Error fetching jobs:', error);
            });
        } catch (error) {
          this.toggleLoading(false);
          console.error('Error fetching jobs:', error);
        }
      },
      editJob(jobId) {
        // Navigate to job editing page or open an editing form (implement as needed)
        this.$router.push(`/edit-job/${jobId}`);
      },
      getExpiryDate(publishDate) {
        if (!publishDate) return null;
        const expiry = new Date(publishDate);
        expiry.setDate(expiry.getDate() + 30);
        return expiry;
      },
      getRemainingDays(publishDate) {
        if (!publishDate) return 0;
        
        const expiry = this.getExpiryDate(publishDate);
        const now = new Date();
        const diff = expiry - now;
        return Math.ceil(diff / (1000 * 60 * 60 * 24));
      },
      calculateTimeRemaining(publishDate) {
        const daysLeft = this.getRemainingDays(publishDate);
        
        if (daysLeft <= 0) {
          return null;
        } else if (daysLeft === 1) {
          return 'Last day';
        } else if (daysLeft <= 7) {
          return `${daysLeft} days left`;
        } else {
          return `${daysLeft} days remaining`;
        }
      },
      formatPostDate(date) {
        return new Date(date).toLocaleDateString('en-AU', {
          day: 'numeric',
          month: 'short',
          year: 'numeric'
        });
      },
      closeJob(job) {
        this.selectedJob = job;
        this.showCloseModal = true;
        this.activeDropdown = null;
        this.closeJobConfirmation = false;
      },
      deleteJob(job) {
        this.selectedJob = job;
        this.showDeleteModal = true;
        this.activeDropdown = null;
        this.deleteDraftConfirmation = false;
      },
      confirmDelete() {
        if (!this.deleteDraftConfirmation) return;

        this.showDeleteModal = false;
        this.toggleLoading(true);

        jobsService.deleteDraft(this.selectedJob.uuid)
          .then(() => {
              // Remove the job from the jobs array
              this.jobs = this.jobs.filter(job => job.uuid !== this.selectedJob.uuid);
              // Show success message with notification
              this.$notify({
                title: 'Success',
                message: 'Draft job deleted successfully',
                type: 'success'
              });
          })
          .catch((error) => {
            console.error('Error deleting draft:', error);
            // You might want to show an error message to the user here
          })
          .finally(() => {
            this.toggleLoading(false);
            this.selectedJob = null;
            this.deleteDraftConfirmation = false;
          });
      },
      confirmClose() {
        if (!this.closeJobConfirmation) return;
        
        this.showCloseModal = false;
        this.toggleLoading(true);

        jobsService.closeJob(this.selectedJob.uuid)
		.then(() => {
			// Update job status in the jobs array
			this.jobs.find(job => job.uuid === this.selectedJob.uuid).status = 4;
			// Show success message with notification
			this.$notify({
				title: 'Success',
				message: 'Job closed successfully',
				type: 'success'
			});
		})
		.catch((error) => {
			console.error('Error closing job:', error);
			// You might want to show an error message to the user here
		})
		.finally(() => {
			this.toggleLoading(false);
			this.selectedJob = null;
        	this.closeJobConfirmation = false;
		});
      },
      toggleDropdown(job) {
        this.activeDropdown = this.activeDropdown === job.id ? null : job.id;
      },
      handleClickOutside(event) {
        if (!event.target.closest('.relative')) {
          this.activeDropdown = null;
        }
      }
    },
    mounted() {
      this.fetchJobs();
      // Check if we're returning from a successful payment
      if (this.$route.query.paymenet_intent !== null && this.$route.query.redirect_status === 'succeeded') {
        this.showSuccessMessage = true;
        // Clean up the URL using Vue Router
        this.$router.replace({ path: '/company/manage-jobs' });
        // Hide the message after 5 seconds
        setTimeout(() => {
          this.showSuccessMessage = false;
        }, 5000);
      }
      document.addEventListener('click', this.handleClickOutside);
    },
    beforeUnmount() {
      document.removeEventListener('click', this.handleClickOutside);
    }
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 1200px;
  }
  th, td {
    border: 1px solid #e5e7eb;
    padding: 12px 16px;
  }
  th {
    text-align: left;
    font-size: 0.875rem;
  }
  button {
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  button:hover {
    transform: translateY(-2px);
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  .fixed {
    animation: fadeIn 0.2s ease-in-out;
  }

  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  </style>