<template>
    <div class="pt-7 flex justify-center">
        <div class="bg-white shadow-md rounded-xl px-8 pt-6 pb-8 mb-4 flex flex-col w-11/12 md:w-1/2">
            <div class="-mt-6 -ml-3 md:-ml-8 -mr-3 md:-mr-8 rounded-t-xl bg-sky-500 pt-4 pl-6 md:pl-8">
                <div class="leading-none tracking-tight mb-3 md:mb-4 text-2xl md:text-3xl text-gray-50">Password reset</div>
            </div>

            <div v-if="this.error" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
                <span class="block sm:inline">You have entered an invalid Email address</span>
            </div>

            <div class="mb-4 flex flex-col my-6" v-if="this.emailSent">
                <span class="text-xl">Password recovery email sent!</span>
                <span class="mt-2">If an account with that email exists in our system, we have sent a password recovery link. Please check your inbox and spam folder. If you do not receive an email, please try again later or contact our support team for assistance. <a class="text-blue-500 hover:text-blue-700 cursor-pointer" href="https://screened.com.au">Click here to return to the homepage.</a></span>
            </div>

            <div class="my-6" v-if="!this.emailSent">
                <div class="flex flex-col">
                  <span class="block text-gray-900 mb-2 font-bold">Forgot Your Password?</span>
                  <span class="block text-gray-800 mb-4">Enter your email address below, and we’ll send you a link to reset your password.</span>
                </div>

                <label class="block text-gray-800 text-sm mb-2" for="email">
                    Email address
                </label>
                <input class="shadow appearance-none border-gray-300 border-red rounded w-full py-2 px-3 text-gray-500 mb-3" id="email" v-model="this.recoveryEmail" type="text">
            </div>

            <div class="flex items-end justify-end" v-if="!this.emailSent">
                <button class="bg-sky-500 hover:bg-sky-400 text-white py-2 px-4 rounded" type="button" @click="submitRecoveryRequest">
                    Send Recovery Email
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import passwordService from '@/services/password.service';

export default {
  name: 'CompanyForgotPassword',
  inject: ['toggleLoading'],
  components: {
  },
  data() {
    return {
      emailSent: false,
      recoveryEmail: "",
      error: false,
      SignInButtonText: "Sign In"
    };
  },
  methods: {
    async submitRecoveryRequest() {
      this.toggleLoading(true);
      let response = await passwordService.sendCompanyPasswordRequest(this.recoveryEmail)

      if (response && response.data) {
          if (response.data.type == 'success') {
              this.emailSent = true
          }
      }

      this.toggleLoading(false);
    },
  },
}
</script>
