<template>
    <div class="pt-7">
      
  </div>
</template>

<script>
let apiEndpoint = process.env.VUE_APP_API_BASEURL

export default {
  name: 'Logout',
  components: {
  },
  data() {
    return {
      uploads: [],
    };
  },
  methods: {
    logoutUser() {
        // Remove localstorage
        localStorage.removeItem('companyToken');

        this.$store.commit('setCompanyLoggedIn', false)

        // Redirect to login
        this.$router.push('/company/login') 
    },
  },
  created() {
    this.logoutUser();
  },
}
</script>
