<template>
    <div>
		<div v-if="isActiveJob" class="bg-blue-50 p-4 rounded-lg mb-6">
            <p class="text-blue-700">
                This job is currently active. You can only edit the job description and start date.
            </p>
        </div>
        <!-- Step Navigation -->
        <div class="flex justify-center items-end mb-6">
            <div v-for="(step, index) in filteredSteps" :key="index" :class="{ 'w-1/3': isProject, 'w-1/2': !isProject }" class="px-1 flex flex-col items-center">
                <div class="w-full h-2 rounded-sm" :class="index <= currentStep ? 'bg-sky-500' : 'bg-gray-300'"></div>
                <div class="text-center text-xs mt-2" :class="index <= currentStep ? 'text-sky-500' : 'text-gray-500'">{{ step.name }}</div>
            </div>
        </div>
  
      <!-- Step Content -->
      <div v-show="currentStep === 0">
        <label for="industry" class="block text-gray-700 mb-1 font-semibold ml-0.5">Industry<span class="text-red-500">*</span></label>
        <select v-model="jobData.selectedIndustry" :disabled="isActiveJob" @change="fetchRoles" name="industry" class="p-2.5 mb-2.5 w-full rounded-md border-2 border-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-300 focus:border-sky-500">
            <option disabled value="">Select industry</option>
            <option v-for="industry in industries" :key="industry.id" :value="industry.id">{{ industry.name }}</option>
        </select>

        <label for="role" class="block text-gray-700 mb-1 font-semibold ml-0.5">Role<span class="text-red-500">*</span></label>
        <select v-model="jobData.selectedRole" :disabled="isActiveJob" name="role" class="p-2.5 mb-2.5 w-full rounded-md border-2 border-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-300 focus:border-sky-500">
            <option disabled value="">{{ !jobData.selectedIndustry ? 'Please select an industry first' : 'Please select a role' }}</option>
            <option v-for="role in roles" :key="role.id" :value="role.id">{{ role.name }}</option>
        </select>

        <!-- Job title -->
        <label for="jobTitle" class="block text-gray-700 mb-1 font-semibold ml-0.5">Job title<span class="text-red-500">*</span></label>
        <input v-model="jobData.title" :disabled="isActiveJob" type="text" name="jobTitle" class="p-2.5 mb-2.5 w-full rounded-md border-2 border-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-300 focus:border-sky-500" />

        <!-- Job description -->
        <label for="jobDescription" class="block text-gray-700 mb-1 font-semibold ml-0.5">Job description<span class="text-red-500">*</span></label>
        <div class="rounded-lg border border-gray-300 overflow-hidden shadow-sm">
          <QuillEditor shallowRef="quillEditor" @update:content="updateContent" @update:delta="updateDelta" id="jobDescription" class="h-96 " :initialContent="initialJobData?.description || null" />
        </div>
      </div>
  
      <div v-show="currentStep === 1">
        <!-- Location -->
        <label for="work_location" class="block text-gray-700 mb-1 font-semibold ml-0.5">Location<span class="text-red-500">*</span></label>
        <input v-model="jobData.work_location" :disabled="isActiveJob"type="text" name="work_location" class="p-2.5 mb-2.5 w-full rounded-md border-2 border-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-300 focus:border-sky-500" />

        <!-- Work type -->
        <label for="work_type" class="block text-gray-700 mb-1 font-semibold ml-0.5">Work type<span class="text-red-500">*</span></label>
        <select v-model="jobData.work_type" :disabled="isActiveJob" name="work_type" class="p-2.5 mb-2.5 w-full rounded-md border-2 border-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-300 focus:border-sky-500">
            <option disabled value="">Select work type</option>
            <option value="casual">Casual</option>
            <option value="contract">Contract</option>
            <option value="full_time">Full Time</option>
            <option value="part_time">Part time</option>
        </select>

        <!-- Length of contract -->
        <div v-if="jobData.work_type == 'contract'">
            <label for="contract_length" class="block text-gray-700 mb-1 font-semibold ml-0.5">Length of contract<span class="text-red-500">*</span></label>
            <input v-model="jobData.contract_length" :disabled="isActiveJob" type="text" name="contract_length" class="p-2.5 mb-2.5 w-full rounded-md border-2 border-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-300 focus:border-sky-500" />
        </div>
        
        <div class="w-full">
            <!-- Label for the entire input -->
            <label for="start_date_group" class="block text-gray-700 mb-1 font-semibold ml-0.5">
              Start date<span class="text-red-500">*</span>
            </label>

              <!-- Input Group -->
              <div id="start_date_group" class="flex items-center w-full p-4 space-x-4 border rounded-lg p-2.5 mb-2.5">
                <!-- ASAP Option -->
                <div class="flex-1">
                  <label class="flex items-center space-x-2 cursor-pointer">
                    <input
                      type="radio"
                      id="asap"
                      name="start_date"
                      value="asap"
                      v-model="startDateOption"
                      @change="handleStartDateChange"
                      class="hidden peer"
                    />
                    <span
                      class="flex select-none items-center justify-center w-full py-2 border-b border-gray-300 text-gray-700 peer-checked:border-sky-500 peer-checked:text-sky-500"
                    >
                      ASAP
                    </span>
                  </label>
                </div>

              <!-- OR Divider -->
              <div class="text-gray-400 font-medium text-sm">OR</div>

              <!-- Date Picker Option -->
              <div class="flex-1">
                <label class="flex items-center space-x-2 cursor-pointer">
                  <input
                    type="radio"
                    id="pick_date"
                    name="start_date"
                    value="pick_date"
                    v-model="startDateOption"
                    @change="handleStartDateChange"
                    @click="handleStartDateChange"
                    class="hidden peer"
                  />
                  <span
                    class="flex select-none items-center justify-center w-full py-2 border-b border-gray-300 text-gray-700 peer-checked:border-sky-500 peer-checked:text-sky-500"
                  >
                    {{ startDateString }}
                  </span>
                </label>

                <Popover v-if="calendarOpen" class="relative">
                    <PopoverPanel class="absolute z-10">
                        <Datepicker class="my-3" v-model="jobData.start_date" :min-date="today" inline autoApply @update:modelValue="updateCalendarDate" hideOffsetDates :enableTimePicker="false" :inline="true" ref="datepicker"></Datepicker>
                    </PopoverPanel>
                </Popover>
              </div>
            </div>
        </div>

        <!-- New rate -->
        <div class="flex flex-col md:flex-row md:space-x-4">
            <div class="flex flex-col w-full md:w-1/3">
                <label for="rate_category" class="block text-gray-700 mb-1 font-semibold ml-0.5">Rate</label>

                <select v-model="jobData.rate_category" :disabled="isActiveJob" name="rate_category" class="p-2.5 mb-2.5 w-full rounded-md border-2 border-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-300 focus:border-sky-500">
                  <option value="undisclosed">Undisclosed</option>
                  <option value="custom_rate">Custom rate</option>
                  <option value="award">Award</option>
                  <option value="negotiable">Negotiable</option>
                  <option value="market_rate">Market rate</option>
              </select>
            </div>
            <div v-show="jobData.rate_category == 'custom_rate'" class="flex flex-col md:flex-row w-full md:w-2/3 md:space-x-4">
                <div class="flex flex-col w-full md:w-1/2">
                    <label for="rate" class="block text-gray-700 mb-1 font-semibold ml-0.5">Rate amount <span class="text-red-500">*</span></label>
                    <input v-model="jobData.rate" :disabled="isActiveJob" name="rate" type="number" class="p-2.5 mb-2.5 w-full rounded-md border-2 border-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-300 focus:border-sky-500" />
                </div>
                <div class="flex flex-col w-full md:w-1/2">
                    <label for="rate" class="block text-gray-700 mb-1 font-semibold ml-0.5">Rate type <span class="text-red-500">*</span></label>
                    <select v-model="jobData.rate_type" name="rateType" class="p-2.5 mb-2.5 w-full rounded-md border-2 border-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-300 focus:border-sky-500">
                        <option disabled value="">Select rate type</option>
                        <option value="hour">Hourly</option>
                        <option value="day">Daily</option>
                    </select>
                </div>
            </div>
        </div>

        <label for="rate" class="block text-gray-700 mb-1 font-semibold ml-0.5">Work Arrangement<span class="text-red-500">*</span></label>
        <select v-model="jobData.work_arrangement" :disabled="isActiveJob" class="p-2.5 mb-2.5 w-full rounded-md border-2 border-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-300 focus:border-sky-500">
            <option disabled value="">Select work arrangement</option>
            <option value="field">Field</option>            
            <option value="hybrid">Hybrid</option>
            <option value="office">Office / On Site</option>
            <option value="wfh">WFH</option>
            <option value="other">Other</option>
        </select>

        <label for="roster" class="block text-gray-700 mb-1 font-semibold ml-0.5">Roster</label>
        <input v-model="jobData.roster" :disabled="isActiveJob" type="text" name="roster" class="p-2.5 mb-2.5 w-full rounded-md border-2 border-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-300 focus:border-sky-500" />

        <label for="hours" class="block text-gray-700 mb-1 font-semibold ml-0.5">Hours</label>
        <input v-model="jobData.hours" :disabled="isActiveJob" type="text" name="hours" class="p-2.5 mb-2.5 w-full rounded-md border-2 border-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-300 focus:border-sky-500" />


        <label for="isProject" class="block text-gray-700 mb-1 font-semibold ml-0.5">Is this for a project?<span class="text-red-500">*</span></label>
        <select v-model="isProject" :disabled="isActiveJob" name="isProject" class="p-2.5 mb-2.5 w-full rounded-md border-2 border-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-300 focus:border-sky-500">
            <option disabled value="">Select an option</option>
            <option :value="true">Yes</option>
            <option :value="false">No</option>
        </select>
      </div>
  
      <div v-if="currentStep === 2">
        <label for="project_description" class="block text-gray-700 mb-1 font-semibold ml-0.5">Project description<span class="text-red-500">*</span></label>
        <textarea v-model="jobData.project_description" :disabled="isActiveJob" type="text" name="project_description" rows="5" class="p-2.5 mb-2.5 w-full rounded-md border-2 border-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-300 focus:border-sky-500" />

        <label for="project_timeframe" class="block text-gray-700 mb-1 font-semibold ml-0.5">Project timeframe</label>
        <input v-model="jobData.project_timeframe" :disabled="isActiveJob" type="text" name="project_timeframe" class="p-2.5 mb-2.5 w-full rounded-md border-2 border-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-300 focus:border-sky-500" />

        <label for="project_stage" class="block text-gray-700 mb-1 font-semibold ml-0.5">Current project stage</label>
        <input v-model="jobData.project_stage" :disabled="isActiveJob" type="text" name="project_stage" class="p-2.5 mb-2.5 w-full rounded-md border-2 border-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-300 focus:border-sky-500" />

        <div class="flex space-x-4">
            <div class="w-1/2 flex flex-col">
                <label for="project_team_size" class="block text-gray-700 mb-1 font-semibold ml-0.5">Project team size</label>
                <input v-model="jobData.project_team_size" :disabled="isActiveJob" type="text" name="project_team_size" class="p-2.5 mb-2.5 w-full rounded-md border-2 border-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-300 focus:border-sky-500" />
            </div>
            <div class="w-1/2 flex flex-col">
                <label for="project_budget" class="block text-gray-700 mb-1 font-semibold ml-0.5">Project budget</label>
                <input v-model="jobData.project_budget" :disabled="isActiveJob" type="text" name="project_budget" class="p-2.5 mb-2.5 w-full rounded-md border-2 border-gray-300 focus:outline-none focus:ring-4 focus:ring-sky-300 focus:border-sky-500" />
            </div>
        </div>
      </div>

      <div v-show="currentStep === 3" class="flex flex-col lg:flex-row gap-8 lg:gap-16">
          <!-- Information Panel -->
          <div class="w-full lg:w-1/2">
              <div class="p-4 lg:p-8">
                  <!-- Header -->
                  <div class="mb-8 lg:mb-12">
                      <h2 class="text-2xl lg:text-3xl font-semibold bg-gradient-to-r from-gray-800 to-gray-600 bg-clip-text text-transparent">Job Posting Summary</h2>
                      <p class="text-sky-600 font-medium mt-3 lg:mt-4 text-base lg:text-lg">{{ jobData.title || 'Software Engineer Position' }}</p>
                  </div>

                  <!-- Content -->
                  <div class="space-y-6 lg:space-y-8">
                      <div class="flex items-start space-x-4 lg:space-x-5 group hover:translate-x-1 transition-transform duration-200">
                          <div class="flex-shrink-0">
                              <div class="p-2 rounded-full bg-gradient-to-br from-sky-50 to-white">
                                  <svg class="w-5 h-5 lg:w-6 lg:h-6 text-sky-500/80 group-hover:text-sky-500 transition-colors" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                  </svg>
                              </div>
                          </div>
                          <div>
                              <h3 class="font-medium text-gray-900 text-base lg:text-lg">30-Day Visibility Period</h3>
                              <p class="text-gray-500 mt-1 lg:mt-2 leading-relaxed text-sm lg:text-base">Your job posting will be prominently featured on the Screened platform for 30 days from the date of publication.</p>
                          </div>
                      </div>

                      <div class="flex items-start space-x-4 lg:space-x-5 group hover:translate-x-1 transition-transform duration-200">
                          <div class="flex-shrink-0">
                              <div class="p-2 rounded-full bg-gradient-to-br from-sky-50 to-white">
                                  <svg class="w-5 h-5 lg:w-6 lg:h-6 text-sky-500/80 group-hover:text-sky-500 transition-colors" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                  </svg>
                              </div>
                          </div>
                          <div>
                              <h3 class="font-medium text-gray-900 text-base lg:text-lg">Maximum Exposure</h3>
                              <p class="text-gray-500 mt-1 lg:mt-2 leading-relaxed text-sm lg:text-base">Your position will be visible to our extensive network of qualified professionals in your industry.</p>
                          </div>
                      </div>

                      <div class="flex items-start space-x-4 lg:space-x-5 group hover:translate-x-1 transition-transform duration-200">
                          <div class="flex-shrink-0">
                              <div class="p-2 rounded-full bg-gradient-to-br from-sky-50 to-white">
                                  <svg class="w-5 h-5 lg:w-6 lg:h-6 text-sky-500/80 group-hover:text-sky-500 transition-colors" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"></path>
                                  </svg>
                              </div>
                          </div>
                          <div>
                              <h3 class="font-medium text-gray-900 text-base lg:text-lg">Flexible Management</h3>
                              <p class="text-gray-500 mt-1 lg:mt-2 leading-relaxed text-sm lg:text-base">Before expiration, you'll have the option to extend visibility or renew the posting to maintain continuous exposure.</p>
                          </div>
                      </div>
                  </div>

                  <!-- Footer Note -->
                  <div class="mt-8 lg:mt-12">
                      <p class="text-sm text-gray-500 leading-relaxed pl-4 border-l-2 border-sky-100">
                          <span class="text-sky-600 font-medium">Note:</span> Your posting will be automatically archived after the 30-day period. You'll receive notifications before expiration to manage your listing's visibility.
                      </p>
                  </div>
              </div>
          </div>

          <!-- Payment Form Section -->
          <div class="w-full lg:w-1/2">
              <div class="p-4 lg:p-8 bg-gradient-to-br from-white to-sky-50/30 min-h-[500px] lg:min-h-[600px] flex flex-col">
                  <h2 class="text-xl lg:text-2xl font-medium text-gray-800 mb-3 lg:mb-4">Complete Your Purchase</h2>
                  <p class="text-gray-500 mb-6 lg:mb-8 text-sm lg:text-base">Please provide your payment details below to post your job listing.</p>
                  
                  <!-- Loading State -->
                  <div v-show="!isStripeLoaded" class="flex-grow flex flex-col items-center justify-center space-y-4">
                      <div class="animate-spin rounded-full h-10 w-10 lg:h-12 lg:w-12 border-b-2 border-sky-500"></div>
                      <p class="text-gray-500 text-sm">Loading payment form...</p>
                  </div>

                  <!-- Payment Form -->
                  <div v-show="isStripeLoaded" class="flex-grow flex flex-col">
                      <div id="payment-element" class="flex-grow mb-6 lg:mb-8"></div>
                      
                      <!-- Total Amount -->
                      <div class="border-t border-gray-100 pt-4 lg:pt-6 mb-4 lg:mb-6">
                          <div class="space-y-2 lg:space-y-3">
                              <div class="flex justify-between items-center">
                                  <span class="text-gray-600 text-sm lg:text-base">Base Price</span>
                                  <span class="text-gray-800 text-sm lg:text-base">$149.00</span>
                              </div>
                              <div class="flex justify-between items-center">
                                  <span class="text-gray-600 text-sm lg:text-base">GST (10%)</span>
                                  <span class="text-gray-800 text-sm lg:text-base">$13.50</span>
                              </div>
                              <div class="flex justify-between items-center pt-2 border-t border-gray-100">
                                  <span class="text-gray-900 font-medium text-sm lg:text-base">Total Amount</span>
                                  <span class="text-lg lg:text-xl font-semibold text-gray-900">$162.50</span>
                              </div>
                          </div>
                          <p class="text-xs lg:text-sm text-gray-500 mt-2">30-day job posting period</p>
                      </div>

                      <!-- Payment Button -->
                      <button 
                          @click="handlePayment" 
                          :disabled="isProcessing"
                          class="w-full bg-sky-500 hover:bg-sky-600 disabled:bg-gray-300 disabled:cursor-not-allowed text-white py-2.5 lg:py-3 px-4 rounded-lg font-medium transition-colors duration-200 flex items-center justify-center space-x-2"
                      >
                          <svg v-if="isProcessing" class="animate-spin -ml-1 mr-3 h-4 w-4 lg:h-5 lg:w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                          <span class="text-sm lg:text-base">{{ isProcessing ? 'Processing...' : 'Pay & Post Job' }}</span>
                      </button>
                      
                      <!-- Security Notice -->
                      <p class="text-xs text-gray-500 mt-4 text-center flex items-center justify-center flex-wrap gap-1">
                          <svg class="w-3 h-3 lg:w-4 lg:h-4 text-gray-400 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"></path>
                          </svg>
                          <span>Your transaction is secured with SSL encryption. Your data is protected.</span>
                      </p>
                  </div>
              </div>
          </div>
      </div>
  
      <!-- Navigation Buttons -->
      <div class="flex flex-col sm:flex-row justify-between items-center gap-4 sm:gap-0 mt-3">
        <!-- Left side - Previous button -->
        <div class="flex items-center w-full sm:w-auto">
          <button 
            v-if="currentStep > 0" 
            @click="previousStep" 
            class="flex items-center space-x-2 text-gray-600 hover:text-gray-800 transition-colors duration-200 text-sm sm:text-base"
          >
            <svg class="w-4 h-4 sm:w-5 sm:h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path>
            </svg>
            <span>Previous</span>
          </button>
        </div>

        <!-- Right side - Next/Save Draft buttons -->
        <div class="flex items-center space-x-3 sm:space-x-4 w-full sm:w-auto">
          <!-- Save button for active jobs -->
          <button 
            v-if="initialJobData?.status === 2 && ((currentStep === 1 && !isProject) || (currentStep === 2 && isProject))"
            @click="handleActiveJobUpdate"
            class="flex-1 sm:flex-none bg-sky-500 hover:bg-sky-600 text-white px-4 sm:px-6 py-2 sm:py-2.5 rounded-lg font-medium transition-colors duration-200 flex items-center justify-center space-x-2 text-sm sm:text-base"
          >
            <span>Save Changes</span>
          </button>

          <button 
            v-if="(initialJobData?.status !== 2 && ((!isProject && currentStep < totalSteps - 2) || (isProject && currentStep < totalSteps - 1))) || (initialJobData?.status === 2 && ((isProject && currentStep < 2) || (!isProject && currentStep === 0)))" 
            @click="nextStep" 
            class="flex-1 sm:flex-none bg-sky-500 hover:bg-sky-600 text-white px-4 sm:px-6 py-2 sm:py-2.5 rounded-lg font-medium transition-colors duration-200 flex items-center justify-center space-x-2 text-sm sm:text-base"
          >
            <span>Next</span>
            <svg class="w-4 h-4 sm:w-5 sm:h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
            </svg>
          </button>

          <!-- Save Draft button -->
          <button 
            v-if="initialJobData?.status !== 2 && currentStep === totalSteps - 1" 
            @click="saveDraft" 
            class="flex-1 sm:flex-none bg-gray-100 hover:bg-gray-200 text-gray-700 px-4 sm:px-6 py-2 sm:py-2.5 rounded-lg font-medium transition-colors duration-200 flex items-center justify-center space-x-2 text-sm sm:text-base"
          >
            <svg class="w-4 h-4 sm:w-5 sm:h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4"></path>
            </svg>
            <span>Save Draft</span>
          </button>
        </div>
      </div>
    </div>
  </template>
  
<script>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import 'quill/dist/quill.snow.css';

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import QuillEditor from '@/components/marketplace/generic/QuillEditor.vue';

import genericService from '@/services/marketplace/generic.service'
import companyService from '@/services/marketplace/company.service'
import jobsService from '@/services/marketplace/jobs.service'

export default {
  name: 'JobForm',
	inject: ['toggleLoading'],
	props: {
		jobUUID: {
			type: String,
			default: null
		},
		initialJobData: {
			type: Object,
			default: () => ({})
		}
	},
	components: {
		QuillEditor,
		Datepicker,
		Popover,
		PopoverButton
	},
	computed: {
		filteredSteps() {
			let steps = this.steps.filter((_, index) => index !== 2 || this.isProject);
			
			// Remove payment step for active jobs
			if (this.initialJobData?.status === 2) {
				steps = steps.filter((_, index) => index < (this.isProject ? 3 : 2));
			}
			
			return steps;
		},
		isActiveJob() {
			return this.initialJobData && this.initialJobData.status === 2;
		},
	},
	data() {
		return {
		stripe: null,
		stripePaymentElement: null,
		today: new Date(),
		paymentData: {
			cardName: null,
			card_number: null,
			card_expiry: null,
			card_cvv: null
		},
		selectedDate: 0,
		isProject: '',
		startDateString: 'Pick a date',
		calendarOpen: false,
		startDateOption: '',
		currentStep: 0,
		totalSteps: 4,
		steps: [
			{ name: 'Role Description' },
			{ name: 'Conditions' },
			{ name: 'Project details (optional)' },
			{ name: 'Review & Post Your Job' }
		],
		jobData: { ...this.initialJobData },
		error: false,
		errorMessage: '',
		industries: [],
		roles: [],
		isStripeLoaded: false,
		isProcessing: false,
		};
	},
	methods: {
		async fetchIndustries() {
			this.toggleLoading(true);
			try {
				const response = await genericService.getIndustries();
				this.industries = response.data;
				this.toggleLoading(false);
			} catch (error) {
				console.error('Error fetching industries:', error);
				this.toggleLoading(false);
			}
		},
		async fetchRoles() {
			this.toggleLoading(true);
			this.roles = [];
			this.jobData.selectedRole = '';

			if (this.jobData.selectedIndustry) {
				try {
					const response = await genericService.getRoles(this.jobData.selectedIndustry);
					this.roles = response.data;
					this.toggleLoading(false);
				} catch (error) {
					console.error('Error fetching roles:', error);
					this.toggleLoading(false);
				}
			}
		},
		updateContent(newContent) {
			this.jobData.description_rendered = newContent
		},
		updateDelta(delta) {
			this.jobData.description = delta
		},
		initiateDefaultValues() {
			this.jobData.work_type = this.jobData.work_type !== undefined ? this.jobData.work_type : '';
			this.jobData.rate_type =  this.jobData.rate_type !== undefined ? this.jobData.rate_type : '';
			this.jobData.work_arrangement = this.jobData.work_arrangement !== undefined ? this.jobData.work_arrangement : '';
			this.jobData.selectedIndustry = this.jobData.selectedIndustry !== undefined ? this.jobData.selectedIndustry : '';
			this.jobData.selectedRole = this.jobData.selectedRole !== undefined ? this.jobData.selectedRole : '';
			this.jobData.rate_category = this.jobData.rate_category !== undefined ? this.jobData.rate_category : 'undisclosed'
		},
		handleStartDateChange() {
			if (this.startDateOption === 'pick_date') {
				// Open the calendar for "Pick a date"
				this.calendarOpen = true;
			} else if (this.startDateOption === 'asap') {
				// Reset for "ASAP"
				this.calendarOpen = false;
				this.startDateString = 'Pick a date';
				this.selectedDate = 1;
			} else {
				// Close the calendar for other options
				this.calendarOpen = false;
			}
		},
		updateCalendarDate(newDate) {
			this.selectedDate = (newDate.getTime() / 1000)
			this.calendarOpen = false

			const formattedDate = newDate.toLocaleDateString('en-AU', {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
			});

			this.startDateString = 'From ' + formattedDate
		},
		stepClass(index) {
			if (this.currentStep === index) {
				return 'text-sm px-4 py-2 bg-sky-500 text-white';
			}
			return index < this.currentStep ? 'text-sm px-4 py-2 bg-green-500 text-white' : 'cursor-pointer text-gray-700';
		},
		goToStep(index) {
			if (index <= this.currentStep) {
				this.currentStep = index;
			}
		},
		getStepData(step) {
			if (step === 0 ) {
				return {
					selectedIndustry: this.jobData.selectedIndustry,
					selectedRole: this.jobData.selectedRole,
					title: this.jobData.title,
					description: this.jobData.description,
					description_rendered:  this.jobData.description_rendered
				}
			} else if (step === 1) {
				return {
					location: this.jobData.work_location,
					work_type: this.jobData.work_type,
					contract_length: this.jobData.contract_length,
					start_date: this.selectedDate,
					rate_category: this.jobData.rate_category,
					rate_type: this.jobData.rate_type,
					rate: this.jobData.rate,
					work_arrangement: this.jobData.work_arrangement,
					roster: this.jobData.roster,
					hours: this.jobData.hours,
					is_project: this.isProject
				}
			} else if (step === 2) {
				return {
					project_description: this.jobData.project_description,
					project_timeframe: this.jobData.project_timeframe,
					project_stage: this.jobData.project_stage,
					project_team_size: this.jobData.project_team_size,
					project_budget: this.jobData.project_budget
				}
			}
		},
		nextStep() {
			if (this.validateCurrentStep()) {
				// Update draft if this is not a published job
				if (this.initialJobData?.status !== 2) {
					this.$emit('update-draft', this.getStepData(this.currentStep), this.currentStep);
				}

				// Manage the case where we jump over project details straight to payment
				if (this.currentStep === 1 && this.isProject === false) {
					this.currentStep = 3;
				} else {
					this.currentStep++;
				}
			}
		},
		previousStep() {
			if (this.currentStep > 0) {
				// Manage case where we jump over project details
				if (this.currentStep === 3 && this.isProject === false) {
					this.currentStep = 1;
				} else {
					this.currentStep--;
				}
			}
		},
		validateCurrentStep() {
			this.error = false
			this.errorMessage = ''

			if (this.currentStep === 0 && (!this.jobData.selectedIndustry || !this.jobData.selectedRole || !this.jobData.title || !this.jobData.description || !this.jobData.description.ops || !this.jobData.description.ops.length)) {
				this.error = true
			} else if (this.currentStep === 1) {
				if (this.isProject === '') {
					this.error = true;
					this.errorMessage = 'Please select if this job post is for a project'
				}

				if (!this.jobData.work_arrangement) {
					this.error = true;
					this.errorMessage = 'Please select a work arrangement'
				}

				if (this.jobData.rate_category == 'custom_rate' && (!this.jobData.rate || !this.jobData.rate_type)) {
					this.error = true;
					this.errorMessage = 'The Rate Amount and Rate Type fields are mandatory when using a custom rate'
				}

				if (this.selectedDate == 0) {
					this.error = true;
					this.errorMessage = 'Please select a valid start date'
				}

				if (!this.jobData.contract_length && this.jobData.work_type && this.jobData.work_type == 'contract') {
					this.error = true;
					this.errorMessage = 'You must provide a contract length for this work type'
				}

				if (!this.jobData.work_type) {
					this.error = true;
					this.errorMessage = 'Please select work type'
				}

				if (!this.jobData.work_location) {
					this.error = true;
					this.errorMessage = 'The Location field is mandatory'
				}
        	} else if (this.currentStep === 2 && (!this.jobData.project_description)) {
            	this.error = true
        	}

			if (this.error == true) {
				if (this.errorMessage == '') {
					this.$root.notify('Please fill out all the mandatory fields.', 'error')
				} else {
					this.$root.notify(this.errorMessage, 'error')
				}
				return false;
			}

			return true;
		},
		async handlePayment() {
			this.isProcessing = true;
			try {
				await this.stripe.confirmPayment({
					elements: this.stripePaymentElement,
					confirmParams: {
						return_url: process.env.VUE_APP_WEBSITE_URL + '/company/manage-jobs'
					}
				})
			} catch (error) {
				console.error('Payment failed:', error);
				// Handle payment failure
			} finally {
				this.isProcessing = false;
			}
		},
		async initiateStripe() {
			try {
				const clientSecret = await this.getPaymentIntent()
        
				const options = {
					clientSecret,
					appearance: {
						theme: 'stripe',
					},
				};

				// Create and mount the Payment Element
				const elements = this.stripe.elements(options);
				let stripePaymentElement = elements.create('payment');
				stripePaymentElement.mount('#payment-element');
				
				this.stripePaymentElement = elements;
				this.isStripeLoaded = true;
			} catch (error) {
				console.error('Failed to initialize Stripe:', error);
			}
		},
		async getPaymentIntent() {
			try {
				const paymentData = {
					'uuid': this.jobUUID
				};
				
				const intent = await companyService.createIntent(paymentData);
				return intent.data.clientSecret;
			} catch (error) {
				console.error('Failed to create payment intent:', error);
				throw error;
			}
		},
		saveDraft() {
			this.$root.notify('Your draft has been saved. You can continue editing it later and complete the publishing process when ready.', 'success')
			this.$router.replace({ path: '/company/manage-jobs', query: { draft: true } })
		},
		submitJob() {
			if (this.validateCurrentStep()) {
				this.jobData.isProject = this.isProject
				this.jobData.start_date = this.selectedDate
				this.$emit('job-submitted', this.jobData);
			}
		},
		async handleActiveJobUpdate() {
			this.toggleLoading(true);
			const updateData = {
				'uuid': this.jobUUID,
				'description': this.jobData.description,
				'description_rendered': this.jobData.description_rendered,
				'start_date': this.selectedDate,
			}
			// Make it so we wait for a response before redirecting
			await jobsService.updateJob(updateData)
			this.toggleLoading(false);
            this.$root.notify('Your job post has been successfully updated.', 'success');
            this.$router.push('/company/manage-jobs');
		},
	},
	async mounted() {
		this.initiateDefaultValues();
		this.fetchIndustries();

		if (this.initialJobData.selectedIndustry) {
			await this.fetchRoles();
			this.jobData.selectedRole = this.initialJobData.selectedRole;
		}

		if (this.initialJobData.description) {
			this.jobData.description = JSON.parse(this.initialJobData.description)
		}

		if (this.initialJobData && Object.keys(this.initialJobData).length > 0) {
			if (this.initialJobData.is_project === 0 || this.initialJobData.is_project === 1) {
				this.isProject = this.initialJobData.is_project == 1 ? true : false
			}

			
			if (this.initialJobData.contract_start_date && this.initialJobData.contract_start_date >= 1) {
				this.selectedDate = this.initialJobData.contract_start_date

				if (this.initialJobData.contract_start_date == 1) {
					this.startDateOption = 'asap'
				} else {
					this.startDateOption = 'pick_date'
					this.updateCalendarDate(new Date(this.initialJobData.contract_start_date * 1000))
				}
			}

			// I hate to do this but edit job messes up with the initial values logic. To fix later
			this.jobData.work_type = this.initialJobData.work_type ? this.initialJobData.work_type : '';
			this.jobData.rate_type =  this.initialJobData.rate_type ? this.initialJobData.rate_type : '';
			this.jobData.work_arrangement = this.initialJobData.work_arrangement ? this.initialJobData.work_arrangement : '';
			this.jobData.rate_category = this.initialJobData.rate_category ? this.initialJobData.rate_category : 'undisclosed'
		}

		this.stripe = await Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY)
	},
	watch: {
		jobUUID: {
			immediate: true, // This will run the handler immediately upon component creation
      		handler(newUUID) {
				if (newUUID) {
					// Only initiate stripe if we have a UUID and if the job is a draft
					if ((!this.initialJobData || this.initialJobData && Object.keys(this.initialJobData).length === 0) || (this.initialJobData && Object.keys(this.initialJobData).length > 0 && (this.initialJobData.status == 0 || this.initialJobData.status == 1))) { // If we have no initial job data or if we have initial job data and it's a draft
						this.initiateStripe()
					}

				}
			}
		}
	},
};
</script>

<style scoped>
.input-field {
    width: 100%;
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 5px;
  }
  button {
    transition: all 0.3s ease;
  }

  /* Add to your existing styles */
input:disabled,
select:disabled,
textarea:disabled {
  background-color: #f3f4f6;
  cursor: not-allowed;
  opacity: 0.7;
}

</style>
