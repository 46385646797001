import axios from 'axios';

let apiEndpoint = process.env.VUE_APP_API_BASEURL

export default {
    async postJob(data) {
        return axios.post(`${apiEndpoint}/company/post-job`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('companyToken'),
            }
        });
    },
    async getJob(jobId) {
        return axios.get(`${apiEndpoint}/company/get-job/` + jobId, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('companyToken'),
            }
        });
    },
    async getRawJob(jobId) {
        return axios.get(`${apiEndpoint}/company/get-raw-job/` + jobId, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('companyToken'),
            }
        });
    },
    updateJob(data) {
        return axios.post(`${apiEndpoint}/company/update-job`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('companyToken'),
            }
        });
    },
    closeJob(jobUUID) {
        return axios.post(`${apiEndpoint}/company/close-job`, {
            jobUUID: jobUUID
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('companyToken'),
            }
        });
    },
    generateDraft() {
        return axios.get(`${apiEndpoint}/company/get-draft`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('companyToken'),
            }
        });
    },
    updateDraft(step, draft, jobUUID) {
        // Include headers
            return axios.post(`${apiEndpoint}/company/update-draft`, {
            step: step,
            draft: draft,
            jobUUID: jobUUID
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('companyToken'),
            }
        });
    },
    deleteDraft(jobUUID) {
        return axios.post(`${apiEndpoint}/company/delete-draft`, {
            jobUUID: jobUUID
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('companyToken'),
            }
        });
    }
}