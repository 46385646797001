// Import the components
import ContractorRegistration from '../views/marketplace/contractor/Register.vue'
import ContractorForgotPassword  from '../views/marketplace/contractor/ForgotPassword.vue'
import ContractorJobs from '@/views/marketplace/contractor/Jobs.vue'
import ContractorViewJob from '@/views/marketplace/contractor/ViewJob.vue'
import ContractorAppliedJobs from '@/views/marketplace/contractor/AppliedJobs.vue'
import ContractorSettings from '@/views/marketplace/contractor/Settings.vue'

import CompanyRegistration from '../views/marketplace/company/Register.vue'
import CompanyForgotPassword from '@/views/marketplace/company/ForgotPassword'
import CompanyDashboard from '../views/marketplace/company/Dashboard.vue'
import CompanyJobPosting from '@/views/marketplace/jobs/JobPosting.vue';
import CompanyManageJobs from '@/views/marketplace/company/ManageJobs.vue';
import CompanyViewJob from '@/views/marketplace/company/ViewJob.vue'
import CompanyCandidateView from '@/views/marketplace/company/ViewCandidate.vue'
import CompanyEditJob from '@/views/marketplace/company/EditJob.vue'
import CompanyPaymentHistory from '@/components/marketplace/company/PaymentHistory.vue'

// Admin
import AdminDashboard from '@/views/marketplace/admin/Dashboard'
import AdminCompanyList from '@/views/marketplace/admin/CompanyList'
import AdminContractorList from '@/views/marketplace/admin/ContractorList'
import AdminJobList from '@/views/marketplace/admin/JobList'
import AdminAuthentication from '@/views/marketplace/admin/Authentication'
import AdminAuthenticationVerify from '@/views/marketplace/admin/AuthenticationVerification'
import AdminViewContractor from '@/views/marketplace/admin/ViewContractor'
import AdminViewJob from '@/views/marketplace/admin/ViewJob'


// ContractorView
import ContractorsView from '@/views/marketplace/contractor/ViewProfilePublic';

// Define the routes
const marketplaceRoutes = [
    // Contractor view
    {
        path: '/contractors/:uuid',
        name: 'ContractorsView',
        component: ContractorsView,
        meta: {
        }
    },
    // Admin authentication
    {
        path: '/dashboard/auth',
        name: 'AdminAuthentication',
        component: AdminAuthentication,
        meta: {
            blankHeader: true,
        }
    },
    {
        path: '/dashboard/auth/:token',
        name: 'AdminAuthenticationVerify',
        component: AdminAuthenticationVerify,
    },
    // Admin dashboard
    {
        path: '/dashboard/',
        name:'AdminDashboard',
        component: AdminDashboard,
        meta: {
          isAdminDashboard: true
        }
    },
    // Admin companies
    {
      path: '/dashboard/companies',
      name:'AdminCompanyList',
      component: AdminCompanyList,
      meta: {
        isAdminDashboard: true
      }
    },
    // Admin jobs
    {
      path: '/dashboard/jobs',
      name:'AdminJobList',
      component: AdminJobList,
      meta: {
        isAdminDashboard: true
      }
    },
    {
        path: '/dashboard/job/:jobUUID',
        name: 'AdminViewJob',
        component: AdminViewJob,
        meta: {
            isAdminDashboard: true
        }
    },
    // Admin contractors
    {
        path: '/dashboard/contractors',
        name: 'AdminContractorList',
        component: AdminContractorList,
        meta: {
          isAdminDashboard: true
        }
    },
    {
        path: '/dashboard/contractors/:contractorId',
        name: 'AdminViewContractor',
        component: AdminViewContractor,
        meta: {
          isAdminDashboard: true
        }
    },
    // Admin view job
    {
        path: '/dashboard/jobs/:companyId',
        name: 'AdminCompanyJobsList',
        component: AdminJobList,
        meta: {
            isAdminDashboard: true
        }
    },
    // Contractor registration
    {
        path: '/contractor/register',
        name: 'ContractorRegistration',
        component: ContractorRegistration,
        meta: {
          blankHeader: true,
          breadcrumbs: false
        }
    },
    // Contractor Forgot password
    {
        path: '/contractor/forgot-password',
        name: 'ContractorForgotPassword',
        component: ContractorForgotPassword,
        meta: {
          blankHeader: true,
          breadcrumbs: false
        }
    },
    {
        path: '/contractor/jobs',
        name: 'ContractorJobs',
        component: ContractorJobs,
        meta: {
            contractorHeader: true,
            breadcrumbs: false
        }
    },
    {
        path: '/contractor/job/:jobId',
        name: 'ContractorViewJob',
        component: ContractorViewJob,
        meta: {
          contractorHeader: true,
          breadcrumbs: false
        }
    },
    {
        path: '/contractor/applied-jobs',
          name: 'ContractorAppliedJobs',
          component: ContractorAppliedJobs,
          meta: {
            contractorHeader: true,
            breadcrumbs: false
          }
    },
    {
        path: '/contractor/settings',
          name: 'ContractorSettings',
          component: ContractorSettings,
          meta: {
            contractorHeader: true,
            breadcrumbs: false
          }
    },
    {
        path: '/company/register',
        name: 'CompanyRegistration',
        component: CompanyRegistration,
        meta: {
          blankHeader: true,
          breadcrumbs: false
        }
    },
    {
        path: '/company/forgot-password',
        name: 'CompanyForgotPassword',
        component: CompanyForgotPassword,
        meta: {
          blankHeader: true,
        }
    },
    {
        path: '/company/dashboard',
        name: 'CompanyDashboard',
        component: CompanyDashboard,
        meta: {
          companyHeader: true,
          breadcrumbs: false
        }
    },
    {
        path: '/company/post-job',
        name: 'CompanyJobPosting',
        component: CompanyJobPosting,
        meta: {
          companyHeader: true,
          breadcrumbs: false
        }
    },
    {
        path: '/company/manage-jobs',
        name: 'CompanyManageJobs',
        component: CompanyManageJobs,
        meta: {
          companyHeader: true,
          breadcrumbs: false
        }
    },
    {
        path: '/company/view-job/:jobId',
        name: 'CompanyViewJob',
        component: CompanyViewJob,
        meta: {
          companyHeader: true,
          breadcrumbs: false
        }
    },
    {
        path: '/company/view-job/:jobId/applicant/:profile_hash',
        name: 'CompanyCandidateView',
        component: CompanyCandidateView,
        meta: {
          companyHeader: true,
          breadcrumbs: false
        }
    },
    {
        path: '/company/edit-job/:jobId',
        name: 'CompanyEditJob',
        component: CompanyEditJob,
        meta: {
          companyHeader: true,
          breadcrumbs: false
        }
    },
    {
        path: '/company/payment-history',
        name: 'CompanyPaymentHistory',
        component: CompanyPaymentHistory,
        meta: {
          companyHeader: true,
          breadcrumbs: false
        }
    }
]

export default marketplaceRoutes;