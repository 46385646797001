<template>
    <div v-if="!loading" class="min-h-screen flex items-center justify-center py-5">
        <div class="w-full max-w-7xl overflow-hidden mx-4 pb-4">
            <div class="p-6 bg-sky-500 rounded-lg mb-4 flex flex-col md:flex-row justify-between">
                <h2 class="leading-none tracking-tight text-xl text-white">You’re viewing a contractor’s profile as an administrator</h2>

                <button @click="copyProfileLinkToClipboard" class="flex items-center bg-white text-sky-600 font-semibold py-2 px-4 rounded-lg shadow-md hover:bg-gray-100 transition mt-2 md:mt-0">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M7.217 10.907a2.25 2.25 0 1 0 0 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186 9.566-5.314m-9.566 7.5 9.566 5.314m0 0a2.25 2.25 0 1 0 3.935 2.186 2.25 2.25 0 0 0-3.935-2.186Zm0-12.814a2.25 2.25 0 1 0 3.933-2.185 2.25 2.25 0 0 0-3.933 2.185Z" />
                    </svg>
                    <span class="ml-2 md:ml-0">Share Profile</span>
                </button>
            </div>

            <div class="flex flex-wrap gap-4">
                <div class="flex-none w-full md:w-1/4">
                    <!-- Container for Profile Picture and Details -->
                    <div class="bg-white shadow-lg rounded-lg p-4 justify-center mb-4">
                        <!-- Profile Picture (Placeholder) -->
                        <div class="w-32 h-32 mx-auto bg-gray-200 rounded-full overflow-hidden mb-2">
                            <!-- Replace with actual image tag -->
                            <img :src="profilePicture" alt="Profile Picture" class="w-full h-full object-cover">
                        </div>
                        <!-- Details Section -->
                        <div class="text-center">
                            <!-- Name and Profile Details -->
                            <h3 class="text-xl font-bold text-gray-800 mb-2">{{ this.profile.first_name }} {{ this.profile.last_name }}</h3>
                            <p class="text-gray-600">{{ this.profile.industry }}</p>
                            <p class="text-gray-600">{{ this.profile.suburb }}, {{ this.profile.state }}</p>
                        </div>
                    </div>
                    <div class="bg-white shadow-lg rounded-lg p-6">
                        <div class="">
                            <div class="font-bold text-gray-500 mb-2 text-center">
                                <p>Profile Details</p>
                            </div>
                            <div class="flex items-center mb-2">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-500 mr-2" viewBox="0 0 24 24" fill="currentColor">
                                    <circle cx="12" cy="12" r="9" stroke="currentColor" stroke-width="2" fill="none" />
                                    <text x="12" y="16" text-anchor="middle" font-size="12" fill="currentColor" font-family="Arial" font-weight="bold">$</text>
                                </svg>
                                <p class="text-gray-600">{{ this.profile.rate }} / {{ this.profile.rate_type }}</p>
                            </div>
                            <div class="flex items-center mb-2">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-500 mr-2" viewBox="0 0 24 24" fill="currentColor">
                                    <circle cx="12" cy="12" r="9" stroke="currentColor" stroke-width="2" fill="none" />
                                    <path d="M12 6v6l3 3" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                <p class="text-gray-600">{{ $filters.screenedAvailability(this.profile.availability) }}</p>
                            </div>
                            <div class="flex items-center mb-2">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-500 mr-2" viewBox="0 0 24 24" fill="currentColor">
                                    <rect x="5" y="3" width="14" height="18" rx="2" ry="2" stroke="currentColor" stroke-width="2" fill="none" />
                                    <circle cx="12" cy="11" r="3" stroke="currentColor" stroke-width="1.5" fill="none" />
                                    <path d="M9 14h6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                                    <path d="M9 17h6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                                </svg>
                                <p class="text-gray-600">{{ this.profile.visa_status  }}</p>
                            </div>
                            <div class="flex items-center mb-2">
                                <!-- Email Icon -->
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-500 mr-2" viewBox="0 0 24 24" fill="currentColor">
                                    <path d="M3 8l7.879 5.879c.171.171.443.171.614 0L21 8V5c0-1.105-.895-2-2-2H5C3.895 3 3 3.895 3 5v3zm0 1.414l7.879 5.879c.171.171.443.171.614 0L21 9.414V19c0 1.105-.895 2-2 2H5c-1.105 0-2-.895-2-2V9.414z" />
                                </svg>
                                <p class="text-gray-600">{{ this.profile.email }}</p>
                            </div>
                            <div class="flex items-center mb-2">
                                <!-- Phone Icon -->
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-500 mr-2" viewBox="0 0 24 24" fill="currentColor">
                                    <path d="M20.01 15.41l-3.75-.44a1.998 1.998 0 00-1.97 1.23l-1.57 3.29c-3.16-1.42-5.69-3.95-7.11-7.11l3.29-1.57c.74-.35 1.18-1.19 1.23-1.97l-.44-3.75A2 2 0 008.01 4H5c-1.1 0-2 .9-2 2 0 8.28 6.72 15 15 15 1.1 0 2-.9 2-2v-3.01c0-1.01-.85-1.86-1.89-1.98z" />
                                </svg>
                                <p class="text-gray-600">{{ this.profile.phone }}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Center Column with 2-Column Layout -->
                <div class="flex-1">
                    <div class="bg-white shadow-lg rounded-lg grid grid-cols-1 sm:grid-cols-2 gap-2 p-6 text-smaller">
                        <div v-if="profile.ai_description && profile.ai_description != 'DECLINED'" class="sm:col-span-2 mb-6">
                            <div class="flex items-center justify-between">
                                <h4 class="text-lg font-semibold text-gray-800">Profile Description</h4>
                            </div>
                            <p class="mt-2 text-gray-600 whitespace-pre-wrap">{{ profile.ai_description }}</p>
                        </div>
                        <!-- Column 1: Total Experience, Current Experience -->
                        <div class="mb-6">
                            <h4 class="text-lg font-semibold text-gray-800">Total Experience</h4>
                            <p class="text-gray-600">{{ $filters.screenedDate(profile.updated_total_experience) }}</p>
                        </div>
                        <div class="mb-6">
                            <h4 class="text-lg font-semibold text-gray-800">Current Job Tenure</h4>
                            <p class="text-gray-600">{{ $filters.screenedDate(profile.updated_experience_company) }}</p>
                        </div>

                        <!-- Column 2: Current Company, Past Companies -->
                        <div class="mb-6">
                            <h4 class="text-lg font-semibold text-gray-800">Current Company</h4>
                            <p class="text-gray-600">{{ profile.current_company || 'N/A' }}</p>
                        </div>
                        <div class="mb-6">
                            <h4 class="text-lg font-semibold text-gray-800">Past Companies</h4>
                            <p class="text-gray-600">
                            <span v-if="profile.companiesFormatted && !showMoreCompanies">
                                {{ profile.companiesFormatted }}
                            </span>
                            <span v-else>
                                {{ $filters.screenedCompanies(profile.companies) }}
                            </span>
                            <span v-if="profile.companiesFormatted">
                                <a @click="switchMoreCompanies" class="text-blue-500 hover:text-blue-700 cursor-pointer">
                                ({{ showMoreCompanies ? 'show less' : 'show more' }})
                                </a>
                            </span>
                            </p>
                        </div>

                        <!-- Column 3: Contract Status -->
                        <div class="mb-6">
                            <h4 class="text-lg font-semibold text-gray-800">Contract Status</h4>
                            <p class="text-gray-600">{{ profile.contract_type }}</p>
                        </div>

                        <!-- Column 4: Questions and Answers (Full Width) -->
                        <div v-if="questions.length > 0 && profile.answers" v-for="(item, key) in questions" class="mb-6 sm:col-span-2">
                            <h4 class="text-lg font-semibold text-gray-800">{{ item.question }}</h4>
                            <p v-if="profile.answers[key]"class="text-gray-600">{{ profile.answers[key].response }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import genericService from '@/services/marketplace/generic.service';
import adminService from '@/services/marketplace/admin.service';

export default {
    name: 'CompanyCandidateView',
    inject: ['toggleLoading'],
    data() {
        return {
            loading: false,
            isLoggedIn: false,
            showMoreCompanies: false,
            profile: [],
            questions: [],
            profilePicture: 'https://via.placeholder.com/128'
        };
    },
    methods: {
        async copyProfileLinkToClipboard() {
            try {
                await navigator.clipboard.writeText(process.env.VUE_APP_WEBSITE_URL + '/contractors/' + this.profile.uuid);
                this.$root.notify('Your public profile URL has been copied to clipboard.', 'success');
            } catch (err) {
                // Don't do anything really, if it's not copied it's not copied
            }
        },
        switchMoreCompanies() {
            this.showMoreCompanies = !this.showMoreCompanies
        },
        async getContractorProfile() {
            this.toggleLoading(true);

            adminService.getContractor(this.$route.params.contractorId)
            .then(response => {
                this.profile = response.data
            })
            .catch((error) => {
                this.$router.push('/dashboard/auth');
            })
            .finally(async() => {
                this.profilePicture = genericService.getProfilePictureLink(this.profile.photo_hash)
                const response = await genericService.getQuestions(this.profile.role_id);
                this.questions = response.data;

                this.toggleLoading(false);
            })
        },
    },
    created() {
        this.getContractorProfile()
    }
}
</script>
