<script setup>
import { ref, onMounted } from 'vue'
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net-dt';

const props = defineProps({
  companies: Array,
});

const options = {
    lengthChange: false,
    order: [[6, 'desc']]
}

const columns = [
    { data: 'name', title: 'Name' },
    { data: 'office_address', title: 'Office' },
    { data: 'industry_name', title: 'Industry'},
    {
        data: null,
        title: 'Manager name',
        render: function ( data, type, row ) {
            return row['first_name'] + ' ' + row['last_name']
        }
    },
    { data: 'email', title: 'Manager email' },
    {
        data: null,
        title: 'Manager position',
        render: function ( data, type, row) {
            if (row['title'] === null) {
                return 'Not provided'
            } else {
                return row['title']
            }
        }
    },
    {
        data: 'creation_date',
        title: 'Created',
        render: function ( data, type, row) {
            return row['creation_date'].split(' ')[0]
        },
        type: 'date',
        width: '10%'
    },
    {
        data: 'jobsActive',
        title: 'Active jobs'
    },
    {
        data: 'jobsPosted',
        title: 'Total jobs',
        render: function (data, type, row) {
            return '<a class="text-sky-600 hover:text-blue-800" href="/dashboard/jobs/'+ row['id'] +'">' + row['jobsPosted'] + '</a>'
        }
    },
]
DataTable.use(DataTablesCore);

let dt;
const table = ref(); // This variable is used in the `ref` attribute for the component
 
onMounted(function () {
  dt = table.value.dt;
});

</script>

<style>
@import 'datatables.net-dt';
</style>

<template>
    <DataTable ref="table" class="cell-border" :columns="columns" :data="companies" :options="options"></DataTable>
</template>