import axios from 'axios';

let apiEndpoint = process.env.VUE_APP_API_BASEURL

export default {
    async checkResetToken(token) {
        return axios.get(`${apiEndpoint}/check-reset-token/${token}`);
    },
    async submitResetPassword(password, token) {
        return await axios.post(`${apiEndpoint}/reset-password`, { password: password, token: token }, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
    },
    async sendContractorPasswordRequest(email) {
        return await axios.post(`${apiEndpoint}/contractor/forgot-password`, { email: email }, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
    },
    async sendCompanyPasswordRequest(email) {
        return await axios.post(`${apiEndpoint}/company/forgot-password`, { email: email }, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
    },
}