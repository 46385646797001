import axios from 'axios';
import store from '@/store';

let apiEndpoint = process.env.VUE_APP_API_BASEURL

export default {
    getIndustries() {
        return axios.get(`${apiEndpoint}/contractor/industries`);
    },
    getRoles(industryId) {
        return axios.get(`${apiEndpoint}/contractor/roles/${industryId}`);
    },
    getQuestions(roleId) {
        return axios.get(`${apiEndpoint}/contractor/questions/${roleId}`);
    },
    getProfilePictureLink(pictureHash) {
        return apiEndpoint + '/pictures/' + pictureHash + '-cropped.jpg';
    },
    getContractorProfileView(uuid) {
        let token = ''

        if (store.state.isContractorLoggedIn) {
            token = 'contractorToken'
        } else if (store.state.isCompanyLoggedIn) {
            token = 'companyToken'
        }

        return axios.get(`${apiEndpoint}/contractors/${uuid}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem(token),
            }
        });
    }
}