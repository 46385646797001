<<template>
  <div class="container mx-auto p-6">
    <div class="bg-white shadow-md rounded-lg p-6">
      <h2 class="text-2xl font-semibold text-gray-800 mb-4">Edit Job Posting</h2>
      
      <!-- Job Form Component with job data -->
      <JobForm 
        v-if="jobData" 
        :jobUUID="jobData.uuid"
        :initialJobData="jobData" 
        @job-submitted="handleJobSubmission" 
        @update-draft="updateDraft" 
      />
      
      <!-- Loading state -->
      <div v-else class="py-8 text-center">
        <div class="animate-pulse flex flex-col items-center">
          <div class="rounded-full bg-slate-200 h-10 w-10 mb-4"></div>
          <div class="h-4 bg-slate-200 rounded w-1/3 mb-2"></div>
          <div class="h-4 bg-slate-200 rounded w-1/4"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JobForm from '@/components/marketplace/jobs/JobForm.vue';
import companyService from '@/services/marketplace/company.service';
import jobsService from '@/services/marketplace/jobs.service';

export default {
  name: 'EditJob',
  inject: ['toggleLoading'],
  components: {
    JobForm
  },
  data() {
    return {
      jobUUID: null,
      jobData: null
    };
  },
  methods: {
    async fetchJobData() {
      this.toggleLoading(true);
      try {
        const response = await jobsService.getRawJob(this.jobUUID);
        this.jobData = response.data;
        
        // Check if job status is not editable (not 0, 1, or 2)
        if (![0, 1, 2].includes(this.jobData.status)) {
          this.$root.notify('This job posting cannot be edited.', 'error');
          this.$router.push('/company/manage-jobs');
          return;
        }

        this.jobData.selectedIndustry = this.jobData.industry;
        this.jobData.selectedRole = this.jobData.role;
        this.toggleLoading(false);
      } catch (error) {
        console.error('Failed to fetch job data:', error);
        this.$root.notify('Failed to load job data', 'error');
        this.toggleLoading(false);
        this.$router.push('/company/manage-jobs');
      }
    },
    async updateDraft(draft, step) {
      try {
        await jobsService.updateDraft(step, draft, this.jobUUID);
      } catch (error) {
        console.error('Failed to update draft:', error);
      }
    },
    handleJobSubmission(jobData) {
      this.toggleLoading(true);
      try {
        jobsService.updateJob(this.jobUUID, jobData)
          .then(response => {
            this.toggleLoading(false);
            this.$root.notify('Your job post has been successfully updated.', 'success');
            this.$router.push('/company/manage-jobs');
          })
          .catch(error => {
            this.$root.notify('Something went wrong, please try again', 'error');
            this.toggleLoading(false);
          });
      } catch (error) {
        this.toggleLoading(false);
      }
    }
  },
  created() {
    this.jobUUID = this.$route.params.jobId;
    if (!this.jobUUID) {
      this.$root.notify('Invalid job ID.', 'error');
      this.$router.push('/company/manage-jobs');
      return;
    }
    this.fetchJobData();
  }
};
</script>

<style scoped>
button {
  transition: all 0.3s ease;
}
</style>