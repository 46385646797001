<template>
  <div class="container mx-auto p-6">  
    <!-- Profile Completion Form -->
    <div v-if="!completedProfile" class="bg-white shadow-md rounded-lg p-6 mb-6">
      <h3 class="text-xl font-semibold text-gray-800 mb-4">Complete Your Profile</h3>
      <p class="text-gray-600 mb-4">
        We're almost there! In order to access all the dashboard features, please fill in the following details:
      </p>

      <form @submit.prevent="submitProfile" class="grid gap-6 md:grid-cols-2">
        <div>
          <label for="first_name" class="block text-gray-700 mb-2">First Name</label>
          <input
            v-model="profile.first_name"
            type="text"
            id="first_name"
            class="w-full border border-gray-300 p-3 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-sky-500"
            placeholder="Enter your first name"
            required
          />
        </div>

        <div>
          <label for="last_name" class="block text-gray-700 mb-2">Last Name</label>
          <input
            v-model="profile.last_name"
            type="text"
            id="last_name"
            class="w-full border border-gray-300 p-3 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-sky-500"
            placeholder="Enter your last name"
            required
          />
        </div>

        <div>
          <label for="phone" class="block text-gray-700 mb-2">Phone</label>
          <input
            v-model="profile.phone"
            type="text"
            id="phone"
            class="w-full border border-gray-300 p-3 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-sky-500"
            placeholder="Enter your phone number"
            required
          />
        </div>

        <div>
          <label for="title" class="block text-gray-700 mb-2">Title</label>
          <input
            v-model="profile.title"
            type="text"
            id="title"
            class="w-full border border-gray-300 p-3 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-sky-500"
            placeholder="Enter your job title"
            required
          />
        </div>

        <div class="md:col-span-2 text-right">
          <button
            type="submit"
            class="bg-sky-500 hover:bg-sky-600 text-white font-semibold py-3 px-6 rounded-lg shadow-md transition duration-150 ease-in-out"
          >
            Save Details
          </button>
        </div>
      </form>
    </div>

    <!-- Main Dashboard Content -->
    <div v-else>
      <!-- Welcome Section -->
      <div class="bg-white shadow-md rounded-lg p-6 mb-6">
        <div class="flex items-center justify-between">
          <div>
            <h2 class="text-2xl font-bold text-gray-800 mb-2 flex items-center">
              <span>Welcome&nbsp;</span>
              <span>
                <template v-if="loading">
                  <ContentLoader :speed="2" :height="40" :width="80">
                    <rect x="0" y="0" rx="4" ry="4" width="80" height="40" />
                  </ContentLoader>
                </template>
                <template v-else>
                  {{ companyData.company_name }}
                </template>
              </span>
            </h2>
            <p class="text-gray-600">Manage your job postings and track applications</p>
          </div>
          <router-link to="/company/post-job">
            <button class="bg-sky-500 text-white px-5 py-2 rounded-lg font-medium hover:bg-sky-600 transition-colors duration-200 flex items-center">
              <svg class="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4"></path>
              </svg>
              Post New Job
            </button>
          </router-link>
        </div>
      </div>

      <!-- Stats Grid -->
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
        <!-- Total Jobs -->
        <div class="bg-white shadow-md rounded-lg p-6">
          <div class="flex items-center justify-between mb-4">
            <h3 class="text-lg font-semibold text-gray-800">Total Jobs</h3>
            <div class="p-2 bg-sky-100 rounded-lg">
              <svg class="w-6 h-6 text-sky-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"></path>
              </svg>
            </div>
          </div>
          <p class="text-3xl font-bold text-sky-500">
            <template v-if="loadingDashboard">
              <ContentLoader :speed="2" :height="40" :width="80">
                <rect x="0" y="0" rx="4" ry="4" width="80" height="40" />
              </ContentLoader>
            </template>
            <template v-else>
              {{ dashboard.total_jobs }}
            </template>
          </p>
        </div>

        <!-- Active Jobs -->
        <div class="bg-white shadow-md rounded-lg p-6">
          <div class="flex items-center justify-between mb-4">
            <h3 class="text-lg font-semibold text-gray-800">Active Jobs</h3>
            <div class="p-2 bg-green-100 rounded-lg">
              <svg class="w-6 h-6 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
              </svg>
            </div>
          </div>
          <p class="text-3xl font-bold text-green-500">
            <template v-if="loadingDashboard">
              <ContentLoader :speed="2" :height="40" :width="80">
                <rect x="0" y="0" rx="4" ry="4" width="80" height="40" />
              </ContentLoader>
            </template>
            <template v-else>
              {{ dashboard.active_jobs }}
            </template>
          </p>
        </div>

        <!-- Total Applications -->
        <div class="bg-white shadow-md rounded-lg p-6">
          <div class="flex items-center justify-between mb-4">
            <h3 class="text-lg font-semibold text-gray-800">Applications</h3>
            <div class="p-2 bg-yellow-100 rounded-lg">
              <svg class="w-6 h-6 text-yellow-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"></path>
              </svg>
            </div>
          </div>
          <p class="text-3xl font-bold text-yellow-500">
            <template v-if="loadingDashboard">
              <ContentLoader :speed="2" :height="40" :width="80">
                <rect x="0" y="0" rx="4" ry="4" width="80" height="40" />
              </ContentLoader>
            </template>
            <template v-else>
              {{ dashboard.total_applicants }}
            </template>
          </p>
        </div>

        <!-- Expired Jobs -->
        <div class="bg-white shadow-md rounded-lg p-6">
          <div class="flex items-center justify-between mb-4">
            <h3 class="text-lg font-semibold text-gray-800">Expired Jobs</h3>
            <div class="p-2 bg-orange-100 rounded-lg">
              <svg class="w-6 h-6 text-orange-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
              </svg>
            </div>
          </div>
          <p class="text-3xl font-bold text-orange-500">
            <template v-if="loadingDashboard">
              <ContentLoader :speed="2" :height="40" :width="80">
                <rect x="0" y="0" rx="4" ry="4" width="80" height="40" />
              </ContentLoader>
            </template>
            <template v-else>
              {{ dashboard.expired_jobs || 0 }}
            </template>
          </p>
        </div>
      </div>

      <!-- Recent Activity Section -->
      <div class="bg-white shadow-md rounded-lg p-6">
        <div class="flex items-center justify-between mb-6">
          <h3 class="text-lg font-semibold text-gray-800">Recent Activity</h3>
          <router-link to="/company/manage-jobs" class="text-sky-500 hover:text-sky-600 text-sm font-medium">
            View All
          </router-link>
        </div>
        
        <div class="space-y-4">
          <template v-if="loadingDashboard">
            <ContentLoader :speed="2" :height="100" :width="800">
              <rect x="0" y="0" rx="4" ry="4" width="800" height="100" />
            </ContentLoader>
          </template>
          <template v-else-if="dashboard.recent_jobs && dashboard.recent_jobs.length > 0">
            <div v-for="job in dashboard.recent_jobs" :key="job.id" class="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
              <div>
                <h4 class="font-medium text-gray-900">{{ job.title }}</h4>
                <p class="text-sm text-gray-500">{{ job.work_location }}</p>
              </div>
              <div class="flex items-center space-x-4">
                <span :class="{
                  'px-2.5 py-0.5 rounded-full text-xs font-medium': true,
                  'bg-green-100 text-green-800': job.status === 2,
                  'bg-gray-100 text-gray-800': job.status === 1,
                  'bg-orange-100 text-orange-800': job.status === 3,
                  'bg-blue-100 text-blue-800': job.status === 4
                }">
                  {{ job.status === 1 ? 'Draft' : job.status === 2 ? 'Active' : job.status === 3 ? 'Expired' : 'Closed' }}
                </span>
                <router-link :to="`/company/view-job/${job.uuid}`" class="text-sky-500 hover:text-sky-600">
                  <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path>
                  </svg>
                </router-link>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="text-center py-8 text-gray-500">
              <!-- No recent job postings -->
               Currently under maintenance, please check back soon.
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import companyService from '@/services/marketplace/company.service';
import { ContentLoader } from 'vue-content-loader'

export default {
  name: 'CompanyDashboard',
  components: {
    ContentLoader,
  },
  inject: ['toggleLoading'],
  data() {
    return {
      companyData: {},
      completedProfile: true,
      profile: {},
      dashboard: {},
      loading: true,
      loadingDashboard: true,
    };
  },
  methods: {
    async getCompanyAccount() {
      this.toggleLoading(true);
      await companyService.getCompanyAccount()
        .then(response => {
          this.companyData = response.data
          this.completedProfile = response.data.first_name === null ? false : true
          this.toggleLoading(false);
          this.loading = false
          this.fetchDashboard()
        })
        .catch(error => {
          this.error = true;
          this.errorMessage = 'There was an issue while submitting your information. Please try filling this form again in a few minutes'
          this.toggleLoading(false);
          this.loading = false
          this.$router.push('/company/logout');
        });
    },
    async submitProfile() {
      this.toggleLoading(true)
      await companyService.completeRegistration(this.profile)
      this.$root.notify('Your profile information has been updated', 'success');
      this.completedProfile = true;
      this.toggleLoading(false)
    },
    async fetchDashboard() {
      try {
        const response = await companyService.getDashboard();
        this.dashboard = response.data;
      } catch (error) {
        console.error('Failed to fetch dashboard data:', error);
      } finally {
        this.loadingDashboard = false;
      }
    },
  },
  mounted() {
    this.getCompanyAccount()
  }
};
</script>

<style scoped>
.container {
  max-width: 1200px;
}
</style>
