<script setup>
import { ref, onMounted, inject } from 'vue'
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net-dt';
import adminService from '@/services/marketplace/admin.service';

const props = defineProps({
  contractors : Array,
});


const toggleLoading = inject("toggleLoading");

const options = {
    lengthChange: false,
    order: [[5, 'desc']]
}

const columns = [
    {
        data: null,
        title: 'Name',
        render: function ( data, type, row ) {
            return row['first_name'] + ' ' + row['last_name']
        }
    },
    {
        data: 'email',
        title: 'Email'
    },
    {
        data: null,
        title: 'Location',
        render: function ( data, type, row ) {
            return row['suburb'] + ', ' + row['state']
        }
    },
    {
        data: 'industry',
        title: 'Industry'
    },
    {
        data: 'role',
        title: 'Role'
    },
    {
        data: 'creation_date',
        title: 'Registered',
        render: function ( data, type, row) {
            return row['creation_date'].split(' ')[0]
        },
        type: 'date',
        width: '10%'
    },
    {
        title: 'Actions',
        className: "dt-center",
        render: function( data, type, row) {
            return `<a href="/dashboard/contractors/${row['id']}">
                        <button class="inline-flex items-center p-2 rounded-full bg-gray-700 hover:bg-sky-600 text-white focus:outline-none" title="View profile">
                            <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.477 0 8.268 2.943 9.542 7-1.274 4.057-5.065 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path>
                            </svg>
                        </button>
                    </a>

                    <button data-id="${row.id}" data-name="${row.first_name + ' ' + row.last_name} " class="btn-resume inline-flex items-center p-2 rounded-full bg-gray-700 hover:bg-sky-600 text-white focus:outline-none" title="Download resume">
                        <svg class="w-4 h-4" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>',
                        </svg>
                    </button>

                    <button data-id="${row.id}" data-uuid="${row.uuid}" class="btn-hidden-file inline-flex items-center p-2 rounded-full bg-gray-700 hover:bg-sky-600 text-white focus:outline-none" title="Export anonymous profile">
                        <svg class="w-4 h-4" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"  d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                        </svg>
                    </button>`
        }
    }
]
DataTable.use(DataTablesCore);


const getResume = async (contractorId, contractorName) => {
  try {
    toggleLoading(true); // Start loading

    const response = await adminService.getContractorResume(contractorId, {
      responseType: "blob", // ✅ Ensure response is treated as a Blob
    });

    const contentType = response.headers["content-type"]; // Axios uses `headers` as an object

    let extension;
    switch (contentType) {
      case "application/pdf":
        extension = "pdf";
        break;
      case "application/msword":
        extension = "doc";
        break;
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        extension = "docx";
        break;
      default:
        extension = null;
    }

    if (response.status === 200 && extension) {
      const outputFileName = `Screened-${contractorId}-${contractorName}.${extension}`;
      const myBlob = response.data; // ✅ Axios stores blob in `data`
      const url = window.URL.createObjectURL(myBlob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFileName);
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } else {
      console.error("Resume could not be retrieved at this time.");
      alert("Your resume could not be retrieved at this time. Please try again later.");
    }
  } catch (error) {
    console.error("Error fetching resume:", error);
    // Handle 401 or other errors if needed
  } finally {
    toggleLoading(false); // Stop loading
  }
}

const getHiddenFile = async (contractorId, uuid) => {
  try {
    toggleLoading(true); // Start loading

    const response = await adminService.getHiddenProfile(contractorId, {
      responseType: "blob", // ✅ Ensure response is treated as a Blob
    });

    const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `ScreenedExport-${uuid}.pdf`);
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error fetching hidden file:", error);
    // Handle 401 or other errors if needed
  } finally {
    toggleLoading(false); // Stop loading
  }
}


let dt;
const table = ref(); // This variable is used in the `ref` attribute for the component
 
onMounted(function () {
  dt = table.value.dt;

  document.addEventListener("click", (event) => {
    if (event.target.closest(".btn-resume")) {
      let button = event.target.closest(".btn-resume");
      let id = button.getAttribute("data-id");
      let name = button.getAttribute("data-name");
      getResume(id, name);
    }

    if (event.target.closest(".btn-hidden-file")) {
      let button = event.target.closest(".btn-hidden-file");
      let id = button.getAttribute("data-id");
      let uuid = button.getAttribute("data-uuid");
      getHiddenFile(id, uuid);
    }
  });
});

</script>

<style>
@import 'datatables.net-dt';
</style>

<template>
    <DataTable ref="table" class="cell-border" :columns="columns" :data="contractors" :options="options"></DataTable>
</template>